import React, {useEffect, useState} from 'react';
import Toast from 'react-bootstrap/Toast';

function ToastError({error}) {
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);

    const returnErrorMessage = (err) => {
        if (err.response?.data) return Object.values(err.response.data).map((v) => v + ' ');
        return error.message;
    }

    useEffect(() => {
        if ((error.response && error.response.status) || error.code) setShow(true);
    }, [error])

    return (
        <Toast show={show} onClose={toggleShow} bg={'warning'}>
            <Toast.Header>
                <strong className="me-auto">{error.response && error.response.status ? error.response.status : error.code}</strong>
            </Toast.Header>
            <Toast.Body>{returnErrorMessage(error)}</Toast.Body>
        </Toast>
    );
}

export default ToastError;