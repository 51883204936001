import {GET_GAMES} from "@actionTypes/gamesActionType";

const initialState = null;

const gamesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_GAMES:
            return action.payload;
        default:
            return state;
    }
};

export default gamesReducer;
