import React, {useEffect, useState} from 'react';
import styles from "@UIComponents/InputBlock/InputBlock.module.css";
import InputBlock from "@UIComponents/InputBlock/InputBlock";
import {useFormikContext} from "formik";
import getSortedCurrencies from "@utils/priceAndCurrencyMethods/getSortedCurrencies";
import {CURRENCIES_OBJECT} from "@constants/Currencies";

const SalesInputPrices = ({price, setSalePrice}) => {
    const { values, errors, touched, handleChange, handleBlur} = useFormikContext();
    const [result, setResult] = useState([]);

    const changeCurrencySymbol = (currencyCode) => {
        switch (currencyCode) {
            case 'USD':
                return "$";
            case 'EUR':
                return "€";
            case 'GBP':
                return "£";
            case 'JPY':
                return "¥";
            case 'CHF':
                return "₣";
            case 'SEK':
                return "kr";
            case 'DKK':
                return "Kr.";
            case 'BRL':
                return "R$";
            case 'CZK':
                return "Kč";
            case 'CAD':
                return "C$";
            case 'AUD':
                return "A$";
            case 'NZD':
                return "NZ$";
            case 'RUB':
                return "₽";
            default:
                return currencyCode;
        }
    }

    useEffect(() =>{
        if (price) {
            setResult(getSortedCurrencies(price.prices));
        } else {
            setResult(CURRENCIES_OBJECT);
        }
    }, [price])

    return (
        <div className={`${styles['sales-grid']}`}>
            <div className="grid-item sale-title"></div>
            <div className="grid-item sale-title">Sale price</div>
            <div className="grid-item sale-title">Tier price</div>
            {Object.keys(result).length && Object.keys(result).map((c, i) => {
                        return <React.Fragment key={i}>
                            <InputBlock
                                setSalePrice={setSalePrice}
                                value={values.salePrices ? values.salePrices[c] : null}
                                status={values.state}
                                index={c}
                                key={i}
                                name={"salePrices"}
                                errors={errors ? errors.salePrices : null}
                                touched={touched ? touched.salePrices : null}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="—"
                                groupLabel={c}
                                labelClass={`grid-item required ${styles['currency-tier-label']}`}
                                sign={changeCurrencySymbol(c)}/>
                            <InputBlock
                                name={"prevSalePrices"}
                                disabled={true}
                                value={result[c]}
                                labelClass={`grid-item required ${styles['currency-tier-label']}`}
                                sign={changeCurrencySymbol(c)}/>
                        </React.Fragment>
                    }
                )
            }
        </div>
    );
};

export default SalesInputPrices;
