import React from 'react';
import {Link} from 'react-router-dom';
import Dropdown from "react-bootstrap/Dropdown";

const DropdownItem = ({title, to, keyword, slag}) => {
    if (!keyword.trim()) {
        return <Dropdown.Item as={'li'}>{title}</Dropdown.Item>;
    }
    const regex = new RegExp(`(${keyword})`, "gi");
    const parts = title.split(regex);

    return (
        <Link
            to={`${slag}-${to}`}
            state={{
                gameName: title
            }}
            className="gamesLink">
            <Dropdown.Item as={'li'}>
                <div className='titleBlock'>
                    {parts
                        .filter(String)
                        .map((part, i) => {
                            const isHighlighted = regex.test(part);
                            if (isHighlighted) {
                                return <b key={i}>{part}</b>;
                            }
                            return <span key={i}>{part}</span>;
                        })
                        .reduce((result, element, index, array) => {
                            if (index > 0 && React.isValidElement(array[index - 1]) && React.isValidElement(element)) {
                                return result.concat(" ", element);
                            }
                            return result.concat(element);
                        }, [])}
                </div>
                <div className='linkBlock'>
                    {to}
                </div>
            </Dropdown.Item>
        </Link>
    );
};

export default DropdownItem;