import moment from "moment";
import {TIME_FORMATS} from "@constants/Time";

function convertToTimeFormat(input) {
    let parsedTime = moment.utc(input, TIME_FORMATS, true);

    if (parsedTime.isValid()) {
        return parsedTime.valueOf();
    } else {
        return '';
    }
}

export default convertToTimeFormat;