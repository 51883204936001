import React, {useState} from 'react';
import styles from './NavigationBar.module.css'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const NavigationBar = ({navigation}) => {
    const [additionalClass, setAdditionalClass] = useState('');
    const toggleClass = (event) => {
        setAdditionalClass(event.target.hash);
    };

    return (
        <div className={styles.navigationBar}>
            {
                navigation.map((n, i) => (
                    <div key={i}>
                        <AnchorLink
                            onClick={toggleClass}
                            className={`navigation-link ${additionalClass === n.anchor ? 'active' : ''}`}
                            href={n.anchor}>{n.title}</AnchorLink>
                    </div>
                ))
            }
        </div>
    );
};

export default NavigationBar;