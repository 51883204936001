import {useContext, useEffect, useState} from "react";
import {AppContext} from "../App";
import {useParams} from "react-router-dom";

export const useSkipInappValidation = () => {
    const {games} = useContext(AppContext);
    const {gameId} = useParams();
    const [skipValidation, setSkipValidation] = useState(false);

    useEffect(() => {
        if (gameId && games) {
            const game = games.find((g) => g.xpromoId === gameId);
            setSkipValidation(!!game.skipValidation);
        }
    }, [games, gameId]);

    return skipValidation;
}