export default function processPurchaseProducts(purchaseProducts, purchaseProductDrafts) {
    // перемещаем Ready инапы вверх
    purchaseProductDrafts.sort((a, b) => (a.state !== 'Ready') ? 1 : ((b.state !== 'Ready') ? -1 : 0));

    // создаем новый массив из purchaseProducts со статусом "активный"
    const purchaseProductsWithStatus = purchaseProducts.map(obj => ({
        ...obj,
        state: 'Active',
        hasActivePurchase: true
    }));

    // создаем новый массив на основе purchaseProducts без элементов с таким же id как в purchaseProductDrafts
    const data = purchaseProductsWithStatus.filter(a => {
        if (purchaseProductDrafts.some(b => b.id === a.id)) {
            purchaseProductDrafts.find(b => b.id === a.id).hasActivePurchase = true;
            return false;
        }
        return true;
    });

    // добавляем в начало массива purchaseProductDrafts
    const endData = [...purchaseProductDrafts, ...data];

    return endData;
}