import React, {useState, useEffect, useContext} from "react";
import alphabeticSort from "@utils/filteringMethods/alphabeticSort";
import {AppContext} from "../App";

export default function useInapp(gameId, inappId) {
    const inappDefault = {
        id: '',
        priceTier: '',
        titles: {
            en: '',
        },
        descriptions: {
            en: '',
        }
    }

    const {inapps} = useContext(AppContext);
    const [temp, setTemp] = useState(inappDefault);

    useEffect(() => {
        if (inapps) {
            inapps.map(inapp => {
                if (inapp.id === inappId) {
                    setTemp(inapp)
                }
            })
        }
    }, [inapps])
    temp.titles = alphabeticSort(temp.titles);
    temp.descriptions = alphabeticSort(temp.descriptions);
    return temp;
}
