import * as Yup from "yup";
import {useParams} from "react-router-dom";
import getInappSlug from "../filteringMethods/getInappSlug";
import {useSkipInappValidation} from "@hooks/useSkipInappValidation";

const InappValidation = (inappsIdArray, uniqLanguageCode, localeIndex, fieldsToDisplay) => {
    const {gameId} = useParams();
    const {inappId} = useParams();
    const inappSlug = getInappSlug(inappsIdArray, gameId);
    const skipValidation = useSkipInappValidation();
    let keysArray = [];

    fieldsToDisplay.forEach((k) => {
        if (k && k.key) {
            keysArray.push(k.key)
        }
    })

    function requiredPairField(object, field, msg) {
        return this.test({
            name: 'requiredPairField',
            exclusive: false,
            message: msg || 'Fill title or remove description',

            test: function(value) {
                const grandParent = this.from?.[1];
                if(grandParent.value[object]) return !(grandParent.value[object][field] && !value)
                return true;
            }
        })
    }

    Yup.addMethod(Yup.string, 'requiredPairField', requiredPairField);

    const dynamicValidationTitles = uniqLanguageCode => {
        //dynamic required validation for required field
        const validateObj = {};
        uniqLanguageCode.forEach(field => {
            validateObj[field] = Yup.string()
                .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only whitespaces')
                .max(100, "You have reached the character limit of 100 symbols")
                .required("This field is required")
        });
        return validateObj;
    };

    const dynamicValidationDescriptions = uniqLanguageCode => {
        //dynamic required validation for required field
        const validateObj = {};
        uniqLanguageCode.forEach(field => {
            validateObj[field] = Yup.string()
                .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only whitespaces')
                .max(100, "You have reached the character limit of 100 symbols")
                .required("This field is required")
        });
        return validateObj;
    };

    const dynamicValidationLocaleCode = localeIndex => {
        const validateObj = {};
        localeIndex.forEach(field => {
            validateObj[field] = Yup.string()
                .max(15, 'You have reached the character limit of 15 symbols')
                .matches(/^[A-Za-z0-9_-]+$/, 'Use only (A-Z, a-z), (0-9) and (_ -)')
                .required("This field is required")
                .test('number of repeats',
                    "This locale code already exist",
                    (value) => {
                        let repeats = 0;
                        keysArray.forEach(l => {
                            if (l === value) repeats++
                        })
                        return repeats < 2
                    })
        });
        return validateObj;
    };

    return Yup.object().shape({
        id: Yup.string()
            //если это новый инап, то проверяю как положено [a-z,0-9,_]
            //а если нет, то пропускаю все подряд .+
            .matches(/^[a-z,0-9_.]+$/, 'Use only (a-z), (0-9), (_) and (.)')
            .max(inappId || skipValidation ? 75 : 75 - gameId.length - 1, "You have reached the character limit of 75 symbols")
            .required("This field is required")
            .notOneOf(skipValidation ? inappsIdArray : inappSlug, "In-app with this id already exist"),
        languageCode: Yup.object().shape({
            ...dynamicValidationLocaleCode(localeIndex),
            0: Yup.string()
                .required("This field is required")
        }),
        titles: Yup.object().shape({
            ...dynamicValidationTitles(uniqLanguageCode),
            en: Yup.string()
                .max(100, "You have reached the character limit of 100 symbols")
                .required("This field is required")
                .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only whitespaces')
        }),
        descriptions: Yup.object().shape({
            ...dynamicValidationDescriptions(uniqLanguageCode),
            en: Yup.string()
                .max(100, "You have reached the character limit of 100 symbols")
                .required("This field is required")
                .matches(/^\s*\S[\s\S]*$/, 'This field cannot contain only whitespaces')
        }),
        priceTier: Yup.string()
            .required("This field is required"),
    });
};

export default InappValidation;