import {GET_TIERS} from "@actionTypes/tiersActionType";

const initialState = [];

const tiersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TIERS:
            return action.payload;
        default:
            return [...state];
    }
};

export default tiersReducer;
