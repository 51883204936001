export const LANGUAGES = [
    'ar',
    'de',
    'en',
    'en_AU',
    'en_CA',
    'en_UK',
    'es',
    'es_ES',
    'es_LAT',
    'es_MX',
    'fr',
    'fr_CA',
    'it',
    'ja',
    'ko',
    'nl',
    'pt',
    'pt_BR',
    'pt_PT',
    'ru',
    'sv',
    'tr',
    'uk',
    'zh',
    'zh_HK',
    'zh_TW',
    'zh_Hans',
    'zh_Hant',
    'zh_Hant-TW',
];

