import React, {useContext} from "react";
import Title from "@UIComponents/Title/Title";
import SearchedList from "@UIComponents/SearchedList/SearchedList";
import {AppContext} from "../../App";

const GamesList = () => {
    const {games} = useContext(AppContext);
    const keys = ["xpromoId", "name"];
    const headTitles = ["Xpromo Id", "Title"];
    const titles = 'name';

    return (
        <div className="mainContent">
            <div className="list-block">
                <div className="title-wrapper">
                    <Title mainText="Games"/>
                </div>
                <hr className="horizontal-line"/>
                <div className="table-wrapper">
                    <SearchedList
                        data={games}
                        keys={keys}
                        titles={titles}
                        headTitles={headTitles}
                        noResultTitle={'There are no such games'}
                        additionalTools={'reset'}/>
                </div>
            </div>
         </div>
    );
}

export default GamesList;