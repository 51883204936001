import axios from "axios";
import {GET_TIERS} from "@actionTypes/tiersActionType";
import {API_VERSION} from "@constants/API";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import refreshToken from "@components/API/refreshToken";
import {API_HOST} from "@constants/ENV";
import axiosInherit from "axios-inherit";
import {CURRENCIES} from "@constants/Currencies";

const instance = axiosInherit(axios);
const getTiers = async (dispatch, credential) => {
    const obj = {
        accessToken: credential
    }

    instance({
        method: 'post',
        url: `https://${API_HOST}/${API_VERSION}/GetPriceTiers`,
        data: new URLSearchParams(obj)
    })
        .then(res => res.data)
        .then(tiers => {
            if (!tiers.error) {
                dispatch({type: GET_TIERS, payload: tiers});
                const missingCurrencies = Object.keys(tiers[0].winrt).filter(currency => !CURRENCIES.includes(currency));
                CURRENCIES.push(...missingCurrencies);
            }
        })
        .catch(reqErr => console.error(reqErr))
};

createAuthRefreshInterceptor(instance, refreshToken, {
    statusCodes: [403]
});

export default getTiers;