import axios from "axios";
import {API_VERSION} from "@constants/API";
import {API_HOST} from "@constants/ENV";

const handleAzureUrl = async () => {
    axios.get(`https://${API_HOST}/${API_VERSION}/GetAuthorizationUrl?redirectUrl=https://${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login/callback`)
        .then(res => res.data)
        .then(data => {
            window.location = data.url;
        })
        .catch(reqErr => console.error(reqErr))
};

export default handleAzureUrl;