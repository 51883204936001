import jsonParsedData from "@hooks/useJsonParsedData";
import axios from "axios";
import {API_VERSION} from "@constants/API";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import refreshToken from "@components/API/refreshToken";
import {API_HOST} from "@constants/ENV";
import axiosInherit from "axios-inherit";

const instance = axiosInherit(axios);
export default function CreatePurchaseProductAlteredPriceDraft(data) {
    //Создание скидки
    const xpromoId = window.location.pathname.match(/gameid-([^/]+)/)?.[1];
    let obj = {
        xpromoId,
        id: data.id,
        pricesJson: data.salePrices,
        startTime: data.startTime,
        endTime: data.endTime,
        accessToken: localStorage.getItem('accessToken'),
    }

    obj = jsonParsedData(obj);

    return instance({
        method: 'post',
        url: `https://${API_HOST}/${API_VERSION}/CreatePurchaseProductAlteredPriceDraft`,
        data: new URLSearchParams(obj)
    })
        .then(function (response) {
            return response.status;
        })
};

createAuthRefreshInterceptor(instance, refreshToken, {
    statusCodes: [403]
});