import React, {useState, useEffect, useRef, useContext} from 'react';
import InappIdComponent from "@inappPageComponents/InappId/InappIdComponent.js";
import NavigationBar from '@inappPageComponents/NavigationBar/NavigationBar.js'
import DropdownComponent from '@components/UI/DropdownComponent/DropdownComponent.js'
import ScrollToTopBtn from "@UIComponents/ScrollToTop/ScrollToTopBtn";
import '../../styles/index.css'
import {Formik, Form} from "formik";
import ModalAddLang from "@UIComponents/Modal/ModalAddLang";
import useInapp from "@hooks/useInapp";
import {useParams} from "react-router-dom";
import Title from "@UIComponents/Title/Title";
import {useNavigate} from 'react-router-dom';
import ActionButtons from "@inappPageComponents/ActionButtons/ActionButtons";
import RollbackButtons from "@inappPageComponents/ActionButtons/RollbackButtons";
import useInappsId from "@hooks/useInappsId";
import InappValidation from "@utils/validations/InappValidation";
import PricesBlock from "@inappPageComponents/Prices/PricesBlock";
import {
    INAPPS_NAVIGATION_INAPP_EDITING,
    INAPPS_NAVIGATION_NEW_INAPP
} from "@constants/Navigation";
import TierCurrencyPrices from "@inappPageComponents/Prices/TierCurrencyPrices";
import LocaleTemplate from "@inappPageComponents/Locales/LocaleTemplate";
import {Button} from "react-bootstrap";
import getAddedLanguages from "@UIComponents/Modal/methods/getAddedLanguages";
import FormObserver from "@helpers/FormObserver";
import createSelectList from "./methods/createSelectList";
import submitData from "./methods/submitData";
import UseGameName from "@hooks/useGameName";
import {AppContext} from "../../../App";
import {displayProductionStatus} from "@utils/UIMethods/displayProductionStatus";
import SaleWarningBlock from "@inappPageComponents/Prices/SaleWarningBlock";
import {CONTENT_EDITOR_ROLE, GAME_QA_ROLE} from "@constants/ENV";
import useBeforeUnload from "@hooks/useBeforeUnload";
import {useSkipInappValidation} from "@hooks/useSkipInappValidation";

const NewInnap = () => {
    const ref = useRef(null);
    const {inappId} = useParams();
    const {gameId} = useParams();
    const oneInapp = useInapp(gameId, inappId);
    const navigate = useNavigate();
    const {tiers, sales} = useContext(AppContext);
    const inappsIdArray = useInappsId(gameId);
    const gameName = UseGameName();
    const skipValidation = useSkipInappValidation();

    const [price, setPrice] = useState();
    const [hasSale, setHasSale] = useState(false);
    const [languagesFields, setLanguagesFields] = useState(getAddedLanguages(oneInapp.titles));
    const [options, setOptions] = useState(createSelectList(tiers, inappId));
    const [valuesChanged, setValuesChanged] = useState(false);
    const [addedItem, setAddedItem] = useState(null);
    const [fieldsToDisplay, setFieldsToDisplay] = useState([]);
    const [uniqLanguageCode, setUniqLanguageCode] = useState(['en']);
    const [localeIndex, setLocaleIndex] = useState([0]);
    const [requiredIndex, setRequiredIndex] = useState(0); //для en локали
    const disabled = oneInapp?.state === "Ready" || localStorage.getItem('group') === GAME_QA_ROLE;


    const validation = InappValidation(inappId ? [null] : inappsIdArray, uniqLanguageCode, localeIndex, fieldsToDisplay);

    useBeforeUnload()

    useEffect(() => {
        //Заполняю пустые дескрипшены и тайтлы
        Object.entries(languagesFields).forEach((title) => {
            if (!ref.current.values.descriptions) ref.current.values.descriptions = {};
            if (title[1]) {
                if (!ref.current.values.titles[title[0]]) ref.current.values.titles[title[0]] = '';
                if (!ref.current.values.descriptions[title[0]]) ref.current.values.descriptions[title[0]] = '';
            }
        })
    }, [languagesFields])

    useEffect(() => {
        setUniqLanguageCode([]);
        setLocaleIndex([]);
        fieldsToDisplay.forEach((k, i) => {

            if (k) {
                setLocaleIndex(prev => [...prev, i]);
            }
            if (k && k.key) {
                setUniqLanguageCode(prev => [...prev, k.key]);
                setUniqLanguageCode(prev => [...new Set(prev)])
            }
        })

        //Ищу индекс обязательного поля
        for (const [index, field] of fieldsToDisplay.entries()){
            if (field && field.key === 'en') {
                setRequiredIndex(index);
                return
            }
        }
    }, [fieldsToDisplay])

    const handleLanguage = (langValue) => {
        setLanguagesFields(langValue);
        Object.keys(langValue).forEach((k) => {
            if (langValue[k] && uniqLanguageCode.indexOf(k) === -1) {
                setFieldsToDisplay((prevState) => [...prevState, {key: k, title: '', description: ''}]);
            }
        })
    }

    const addItem = (event) => {
        setAddedItem('');
        setFieldsToDisplay((prevState) => [...prevState, {key: '', title: '', description: ''}]);
        event.currentTarget.blur();
        setTimeout(() => {
            setAddedItem(null);
        }, 800)
    }

    const requestProcessing = (status, setSubmitting, lastMethodProcessed, message) => {
        if (lastMethodProcessed) {
            if (status === 200) navigate(`/games/gameid-${gameId}/all-inapps`, {state: {updateCompleted: true, message}});
            setSubmitting(false)
        }
    }

    useEffect(() => {
        setLanguagesFields(getAddedLanguages(oneInapp.titles));

        setFieldsToDisplay([]);
        Object.keys(oneInapp.titles).forEach( t => {
            setFieldsToDisplay(prev => [...prev, {key: t, title: oneInapp.titles[t], description: oneInapp.descriptions[t]}])
        })
    }, [oneInapp])

    useEffect(() => {
        setOptions(createSelectList(tiers, inappId));
    }, [tiers]);

    useEffect(() => {
        if (sales) setHasSale(!!sales.find(({id}) => id === inappId));
    }, [sales]);

    return (
        <div className="mainContent">
            <div className="title-status-block title-wrapper">
                <Title mainText={`${inappId ? "Editing in-app:" : "New in-app:"}`}
                       secondaryText={gameName}/>
                {
                    inappId &&
                    <>
                        <div
                            className={`status-wrapper ${oneInapp.state ? oneInapp.state.toLowerCase()
                                .replace(' ', '') : ''}`}>{oneInapp.state}</div>
                        {displayProductionStatus(oneInapp.state, oneInapp.hasActivePurchase)}
                    </>
                }
            </div>
            <hr className="horizontal-line"/>
            <Formik
                innerRef={ref}
                enableReinitialize={true}
                initialValues={oneInapp}
                validationSchema={validation}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, {setSubmitting}) => {
                    submitData({...values, gameId, skipValidation}, 'submit', setSubmitting, requestProcessing);
                }}
            >
                {
                    ({
                         setFieldValue
                     }) => {
                        return (
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                setTimeout(() => {
                                    document.querySelector(".is-invalid")?.scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    });
                                }, 100)
                            }}>
                                <FormObserver defaultValues={oneInapp}
                                              setValuesChanged={setValuesChanged}/>
                                <div className="innap-body">
                                    <div className="newinapp-left-block">
                                        {/*****************************************/}
                                        <div className="newinapp-section">
                                            <div id="localization"></div>
                                            <h2 className="form-title">Product name localizations</h2>
                                            <div>
                                                <LocaleTemplate elemKey={'en'}
                                                                index={requiredIndex}
                                                                fieldsToDisplay={fieldsToDisplay}
                                                                addedItem={addedItem}
                                                                required={true}/>
                                                {fieldsToDisplay && fieldsToDisplay.map((k, i) => {
                                                    if (k && i !== requiredIndex) {
                                                        return (<LocaleTemplate elemKey={k.key}
                                                                                disabled={disabled}
                                                                                index={i}
                                                                                addedItem={addedItem}
                                                                                fieldsToDisplay={fieldsToDisplay}
                                                                                setFieldsToDisplay={setFieldsToDisplay}/>
                                                        )
                                                    }
                                                })}
                                            </div>
                                            <div className="locale-actions-block">
                                                <ModalAddLang handleLanguage={handleLanguage}
                                                              localeIndex={localeIndex}/>
                                                <Button className='addRemoveSalesBtn'
                                                        variant="outline-primary"
                                                        disabled={localeIndex.length > 31 || disabled}
                                                        onClick={(event) => addItem(event)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M14 1C14.5523 1 15 1.44772 15 2V14C15 14.5523 14.5523 15 14 15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1H14ZM2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2Z" fill="#0D6EFD"/>
                                                        <path d="M8 4C8.27614 4 8.5 4.22386 8.5 4.5V7.5H11.5C11.7761 7.5 12 7.72386 12 8C12 8.27614 11.7761 8.5 11.5 8.5H8.5V11.5C8.5 11.7761 8.27614 12 8 12C7.72386 12 7.5 11.7761 7.5 11.5V8.5H4.5C4.22386 8.5 4 8.27614 4 8C4 7.72386 4.22386 7.5 4.5 7.5H7.5V4.5C7.5 4.22386 7.72386 4 8 4Z" fill="#0D6EFD"/>
                                                    </svg>
                                                    Add locale
                                                </Button>
                                            </div>
                                        </div>

                                        <div className="newinapp-section">
                                            <div id="prices"></div>
                                            <h2 className="form-title">Price</h2>
                                            <DropdownComponent
                                                options={options}
                                                label={'priceTier'}
                                                labelClass={'form-label required'}
                                                price={price}
                                                setPrice={setPrice}
                                                additionalSign="$"
                                                onChange={(option) => setFieldValue('priceTier', option.id)}
                                            />
                                            <TierCurrencyPrices data={price}/>
                                            {hasSale ?
                                                <SaleWarningBlock gameId={gameId} inappId={inappId} />
                                            :
                                                <PricesBlock price={price} disabled={!oneInapp.hasActivePurchase || localStorage.getItem('group') !== CONTENT_EDITOR_ROLE} />
                                            }
                                        </div>

                                        {!inappId && <div className="newinapp-section">
                                            <div id="xpromo"></div>
                                            <InappIdComponent index={'id'}/>
                                        </div>}
                                    </div>

                                    <div className="newinapp-right-block">
                                        <NavigationBar
                                            navigation={inappId ? INAPPS_NAVIGATION_INAPP_EDITING : INAPPS_NAVIGATION_NEW_INAPP}
                                        />
                                    </div>

                                </div>
                                <div className="action-block">
                                    <div className='inner-action-block'>
                                        <ActionButtons
                                            inappId={inappId}
                                            valuesChanged={valuesChanged}
                                        />
                                    </div>
                                    <div>
                                        <RollbackButtons/>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
            </Formik>
            <ScrollToTopBtn/>
        </div>
    );
};

export default NewInnap;
