import axios from "axios";
import {API_VERSION} from "@constants/API";
import jsonParsedData from "@hooks/useJsonParsedData";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import refreshToken from "@components/API/refreshToken";
import {API_HOST} from "@constants/ENV";
import axiosInherit from "axios-inherit";

const instance = axiosInherit(axios);
export default function UpdatePurchaseProductAlteredPriceDraft (data) {
    const xpromoId = window.location.pathname.match(/gameid-([^/]+)/)?.[1];
    const obj = jsonParsedData({
        ...data,
        xpromoId,
        pricesJson: data.salePrices,
        accessToken: localStorage.getItem('accessToken'),
        salePrices: undefined
    });

    return instance({
        method: 'post',
        url: `https://${API_HOST}/${API_VERSION}/UpdatePurchaseProductAlteredPriceDraft`,
        data: new URLSearchParams(obj),
    })
        .then(function (response) {
            return response.status;
        })
};

createAuthRefreshInterceptor(instance, refreshToken, {
    statusCodes: [403]
});