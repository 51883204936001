import styles from "@UIComponents/TableComponents/table.module.css";

export const displayProductionStatus = (state, purchaseStatus) => {
    if (state === "Active")
        return <div className={styles['production-wrapper']}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <circle cx="8" cy="8.5" r="8" fill="rgba(25, 135, 84, .5)"/>
            </svg>
            Published</div>
    if (purchaseStatus)
        return <div className={styles['production-wrapper']}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <path d="M12.8536 0.646447C12.6583 0.451184 12.3417 0.451184 12.1465 0.646447L10.5 2.2929L14.2071 6.00001L15.8536 4.35355C16.0488 4.15829 16.0488 3.84171 15.8536 3.64645L12.8536 0.646447Z" fill="rgba(13, 110, 253, .7)"/>
                <path d="M13.5 6.70711L9.7929 3.00001L3.29291 9.5H3.5C3.77614 9.5 4 9.72386 4 10V10.5H4.5C4.77614 10.5 5 10.7239 5 11V11.5H5.5C5.77614 11.5 6 11.7239 6 12V12.5H6.5C6.77614 12.5 7 12.7239 7 13V13.2071L13.5 6.70711Z" fill="rgba(13, 110, 253, .7)"/>
                <path d="M6.03166 14.1755C6.01119 14.1209 6 14.0617 6 14V13.5H5.5C5.22386 13.5 5 13.2761 5 13V12.5H4.5C4.22386 12.5 4 12.2761 4 12V11.5H3.5C3.22386 11.5 3 11.2761 3 11V10.5H2.5C2.43827 10.5 2.37915 10.4888 2.32455 10.4684L2.14646 10.6464C2.09858 10.6943 2.06092 10.7514 2.03578 10.8143L0.0357762 15.8143C-0.0385071 16 0.00502989 16.2121 0.146461 16.3536C0.287892 16.495 0.500001 16.5385 0.68571 16.4642L5.68571 14.4642C5.74858 14.4391 5.80569 14.4014 5.85357 14.3536L6.03166 14.1755Z" fill="rgba(13, 110, 253, .7)"/>
            </svg>
            Published (editing)</div>
    return <div className={styles['production-wrapper']}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z" fill="rgba(220, 53, 69, .5)"/>
        </svg>
        Unpublished</div>
}