import React from 'react';
import styles from './Title.module.css'

const Title = ({mainText, secondaryText}) => {
    return (
            <div className={styles.subheader}>
                <h1 className={styles.subheaderMain}> {mainText && mainText} </h1>
                <h1 className={styles.subheaderSecondary}> {secondaryText && secondaryText}</h1>
            </div>
    );
};

export default Title;