import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import button from "bootstrap/js/src/button";
import {ErrorMessage, useFormikContext} from "formik";
import convertLocalToUTCDate from '@utils/dateMethods/convertLocalToUTCDate.js'
import convertUTCToLocalDate from '@utils/dateMethods/convertUTCToLocalDate.js'
import {CONTENT_EDITOR_ROLE} from "@constants/ENV";
import convertToDateFormat from "@utils/dateMethods/convertToDateFormat";

const DatepickerDate = ({
                            label,
                            selected,
                            startDate,
                            endDate,
                            onChange,
                            errors,
                            errorName,
                            status
                        }) => {
    const { isSubmitting} = useFormikContext();
    const [validationError, setValidationError] = useState(!!(errors && errors.length));
    const disabled = status === "Ready" || localStorage.getItem('group') !== CONTENT_EDITOR_ROLE;

    useEffect(() => {
        if (isSubmitting) setValidationError(!!(errors && errors.length));
    }, [isSubmitting])

    return (
        <div className="datepicker-wrapper date">
            <label className="form-label required">{label}</label>
            <div className={`datepicker-subwrapper ${/*validationError*/errors ? "is-invalid" : ""}`}>
                <DatePicker
                    disabled={disabled}
                    selected={convertUTCToLocalDate(selected)}
                    onChange={(option) => onChange(convertLocalToUTCDate(option))}
                    className={`form-control ${/*validationError*/errors ? "is-invalid" : ""}`}
                    isClearable
                    dateFormat="dd.MM.yyyy"
                    startDate={convertUTCToLocalDate(startDate, 'withoutTime')}
                    endDate={convertUTCToLocalDate(endDate, 'withoutTime')}
                    minDate={new Date()}
                    placeholderText="DD.MM.YYYY"
                    strictParsing
                    onChangeRaw={(event) => {
                        const parsedDate = convertToDateFormat(event.target.value);
                        if (parsedDate) {
                            onChange(parsedDate);
                        }
                    }}
                />
                <button
                    disabled={disabled}
                    type="button"
                    className="react-datepicker__close-icon disabled"></button>
            </div>

            <ErrorMessage
                component="div"
                name={`${errorName}`}
                className="invalid-feedback"
            />

        </div>
    );
};

export default DatepickerDate;