import React from 'react';
import styles from './ResultsCount.module.css'

const ResultsCount = ({resNum}) => {
    return (
        <tbody>
        <tr>
            <td className={styles.searchResBlock}>
                <span>Search results:</span>
                {
                    resNum > 0 ?
                        <span> {resNum} matches</span>
                        : <span> no results found</span>
                }
            </td>
        </tr>
        </tbody>
    );
};

export default ResultsCount;