import React, {useEffect} from 'react';

const UseBeforeUnload = () => {
    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        const handleBeforeUnload = () => {
            window.addEventListener("beforeunload", unloadCallback);
        };

        // Проверяем текущий URL
        const currentUrl = window.location.pathname;
        if (currentUrl !== "/login") {
            handleBeforeUnload();
        }
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);
};

export default UseBeforeUnload;
