import { combineReducers } from "redux";
import inappsReducer from './inapps'
import gamesReducer from "./games";
import salesReducer from "./sales";
import userDataReducer from "./userData";
import tiersReducer from "./tiers";

const appReducers = combineReducers({
    userData: userDataReducer,
    games: gamesReducer,
    inapps: inappsReducer,
    sales: salesReducer,
    tiers: tiersReducer,
});

export default appReducers;
