import React from 'react';
import {LANGUAGES} from "@constants/Languages";

const getAddedLanguages = (languages) => {
    let newObj = {};

    for (let key in languages) {
        newObj[key] = true;
    }
    LANGUAGES.forEach((item) =>  {
        if(!newObj.hasOwnProperty(item)) newObj[item] = false;
    })
    return newObj;
};

export default getAddedLanguages;