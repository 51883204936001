/********GAMES************/
export const GAMES_PATH = '/games';
/********ALL INAPPS************/
export const INAPPS_GROUPS_PATH = '/games/gameid-:gameId';
export const INAPPS_LIST_ALL_PATH = '/games/gameid-:gameId/all-inapps';
export const NEW_INAPP_PATH = '/games/gameid-:gameId/all-inapps/new-inapp';
export const INAPP_EDITING_PATH = '/games/gameid-:gameId/all-inapps/inappid-:inappId';
/********SALES INAPPS************/
export const INAPPS_LIST_SALE_PATH = '/games/gameid-:gameId/sales-inapps';
export const NEW_SALE_PATH = '/games/gameid-:gameId/sales-inapps/new-sale';
export const SALE_INAPP_EDITING_PATH = '/games/gameid-:gameId/sales-inapps/inappid-:inappId';
/********TECHNICAL ROUTS************/
export const LOGIN_PATH = '/login';
export const CALLBACK_PATH = '/login/callback';
export const ERROR_PATH = '/page_not_found';
export const HOME_PATH = '/';