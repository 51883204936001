import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import {OverlayTrigger} from "react-bootstrap";
import convertLocalToUTCDate from '@utils/dateMethods/convertLocalToUTCDate.js'
import convertUTCToLocalDate from '@utils/dateMethods/convertUTCToLocalDate.js'
import renderTooltip from "../Tooltip/Tooltip";
import {ErrorMessage} from "formik";
import {CONTENT_EDITOR_ROLE} from "@constants/ENV";
import convertToTimeFormat from "@utils/dateMethods/convertToTimeFormat";

const DatepickerTime = ({label, selected, onChange, tooltipText, status, errors, errorName}) => {
    const disabled = status === "Ready" || localStorage.getItem('group') !== CONTENT_EDITOR_ROLE;

    return (
        <div className="datepicker-wrapper time">
            <label className="form-label required">{label}</label>
            <div className={`overlay-block ${errors ? "is-invalid" : ""}`}
            >
                <DatePicker
                    disabled={disabled}
                    selected={convertUTCToLocalDate(selected)}
                    onChange={(option) => onChange(convertLocalToUTCDate(option))}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    className={`form-control ${
                        errors ? "is-invalid" : ""
                    }`}
                    placeholderText="HH.MM AM/PM"
                    strictParsing
                    onChangeRaw={(event) => {
                        const parsedTime = convertToTimeFormat(event.target.value);
                        if (parsedTime) {
                            onChange(parsedTime);
                        }
                    }}
                />
                <div className="info-icon-block">
                    <OverlayTrigger
                        className="info-icon-block"
                        placement="top"
                        delay={{show: 250, hide: 200}}
                        overlay={renderTooltip(tooltipText)}
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                                stroke="#6C757D"/>
                            <path d="M7.73242 4.80005H8.26576L7.99909 8.66672L7.73242 4.80005Z" stroke="#6C757D"
                                  strokeLinejoin="round"/>
                            <path
                                d="M7.99922 11.7333C8.44105 11.7333 8.79922 11.3751 8.79922 10.9333C8.79922 10.4915 8.44105 10.1333 7.99922 10.1333C7.55739 10.1333 7.19922 10.4915 7.19922 10.9333C7.19922 11.3751 7.55739 11.7333 7.99922 11.7333Z"
                                fill="#6C757D"/>
                        </svg>
                    </OverlayTrigger>
                </div>
            </div>
            <ErrorMessage
                component="div"
                name={`${errorName}`}
                className="invalid-feedback"
            />
        </div>
    );
};

export default DatepickerTime;