import jsonParsedData from "@hooks/useJsonParsedData";
import axios from "axios";
import {API_VERSION} from "@constants/API";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import refreshToken from "@components/API/refreshToken";
import {API_HOST} from "@constants/ENV";
import axiosInherit from "axios-inherit";
import {removeNullCharacters} from "@utils/textPrettier/removeNullCharacters";

const instance = axiosInherit(axios);
export default function CreatePurchase(data) {
    //Создание нового инапа
    const {gameId, skipValidation} = data;

    data.titles = removeNullCharacters(data.titles);
    data.descriptions = removeNullCharacters(data.descriptions);

    let obj = jsonParsedData(data);
    obj.xpromoId = gameId;
    obj.id = skipValidation ? data.id : gameId + '.' + data.id;
    obj.accessToken = localStorage.getItem('accessToken');

    ['titles', 'descriptions', 'salePrices'].forEach((name) => {
        if (!obj[name]) return;
        obj[`${name}Json`] = obj[name]
        delete [name]
    })
    delete obj['state'];

    return instance({
        method: 'post',
        url: `https://${API_HOST}/${API_VERSION}/CreatePurchaseProductDraft`,
        data: new URLSearchParams(obj),
    })
        .then(function (response) {
            return response.status;
        })
};

createAuthRefreshInterceptor(instance, refreshToken, {
    statusCodes: [403]
});
