import jsonParsedData from "@hooks/useJsonParsedData";
import axios from "axios";
import {API_VERSION} from "@constants/API";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import refreshToken from "@components/API/refreshToken";
import {API_HOST} from "@constants/ENV";
import axiosInherit from "axios-inherit";
import {removeNullCharacters} from "@utils/textPrettier/removeNullCharacters";

const instance = axiosInherit(axios);
export default function updatePurchase(data) {
    //Редактирование active инап
    data.titles = removeNullCharacters(data.titles);
    data.descriptions = removeNullCharacters(data.descriptions);

    let obj = {}
    obj = jsonParsedData(data);
    if(!obj.isSales) obj.deleteSale = true;

    ['titles', 'descriptions', 'salePrices'].forEach((name) => {
        if (!obj[name]) return;
        obj[`${name}Json`] = obj[name]
        delete [name]
    })
    obj.accessToken = localStorage.getItem('accessToken');
    delete obj.status;

    return instance({
        method: 'post',
        url: `https://${API_HOST}/${API_VERSION}/CreatePurchaseProductDraft`,
        data: new URLSearchParams(obj),
    })
        .then(function (response) {
            return response.status;
        })
};

createAuthRefreshInterceptor(instance, refreshToken, {
    statusCodes: [403]
});