import moment from "moment";
import {DATE_FORMATS} from "@constants/Time";

function convertToDateFormat(input) {
    let parsedDate = moment.utc(input, DATE_FORMATS, true);

    if (parsedDate.isValid()) {
        return parsedDate.valueOf();
    } else {
        return NaN;
    }
}

export default convertToDateFormat;