import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup} from "react-bootstrap";
import './FilterGroup.css'
import {v4} from "uuid";

const FilterGroup = ({options, onClick, disable, setPage, filterType}) => {

    const url = new URL(window.location);

    const [additionalClass, setAdditionalClass] = useState('');

    const handleClick = (e) => {
        onClick(e.target.value);
        setAdditionalClass(e.target.value);

        setPage(1);
        url.searchParams.set('page', '1');
        window.history.pushState({}, '', url);
        e.currentTarget.blur();
    }

    useEffect(() => {
        setAdditionalClass(filterType)
    }, [filterType])

    return (
            <ButtonGroup className="filter-btn-group">
                {
                    options.map(b => <Button
                        key={v4()}
                        className={`filter-btn filter-${b.value ? b.value : 'allstatuses'} ${b.value === additionalClass ? 'selected' : ''}`}
                        variant="outline-primary"
                        disabled={disable}
                        onClick={handleClick}
                        value={b.value}>{b.label} ({b.amount})</Button>)
                }
            </ButtonGroup>
    );
};

export default FilterGroup;