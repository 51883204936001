import React, {useEffect, useState} from "react";
import SearchControl from "../SearchControl/SearchControl";
import searchingCondition from "@utils/filteringMethods/searchingCondition";
import THead from "../TableComponents/THead";
import TBody from "../TableComponents/TBody";
import Pagination from "../Pagination/Pagination";
import ResultsCount from "../ResultsCount/ResultsCount";
import NoResults from "../../inapps_list/NoResults/NoResults";
import Loading from "../Loading/Loading";
import filteringCondition from "@utils/filteringMethods/filteringCondition";
import {useSearchParams} from "react-router-dom";
import {createBrowserHistory} from "history";

const SearchedList = ({data, keys, titles, headTitles, additionalTools, noResultTitle, addNewInappBtn, category, canAddSale}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const pageNum = searchParams.get('page');

    const [properties, setProperties] = useState([]);
    const [searchedList, setSearchedList] = useState([]);
    const [page, setPage] = useState(pageNum ? pageNum : 1);
    const [showedList, setShowedList] = useState([]);
    const [showResNum, setShowResNum] = useState(false);
    const [filterType, setFilterType] = useState('');
    const history = createBrowserHistory();
    const url = new URL(window.location);
    let tableContent;
    let tempTableContent;

    useEffect(() => {
        if (data) setSearchedList([...data.filter(el => filteringCondition(el, filterType))]);
        if (headTitles) setProperties(headTitles);
    }, [data]);

    useEffect(() => {
        setShowedList(
            searchedList.slice(
                (page - 1) * 10,
                page * 10 - 1 > searchedList.length ? searchedList.length : page * 10
            )
        );
    }, [page, searchedList]);

    const onSearch = (searchedList, showResNum) => {
        setSearchedList([...searchedList]);
        if (showResNum !== undefined) setShowResNum(showResNum);
    }
    const handleChange = (value) => {
        setPage(value);
        url.searchParams.set('page', value);
        window.history.pushState({}, '', url);
    };

    history.listen(({ location}) => {
        setPage(location.search ? new URLSearchParams(location.search).get('page') : 1)
    });

    if (showedList.length) {
        tempTableContent = null;
        tableContent = <>
                {showResNum && <ResultsCount resNum={searchedList.length}/>}
                <TBody data={showedList}/>
            </>;
    } else if (data === null) {
        tempTableContent = <Loading background='transparent' text="Fetching data list"/>;
        tableContent = null;
    } else {
        tempTableContent = <NoResults
            title={noResultTitle}
            category={category}
            canAddSale={canAddSale}
            button={addNewInappBtn}
            group={localStorage.getItem('group')}/>
        tableContent = null;
    }

    return (
        <>
            <SearchControl
                searchingCondition={searchingCondition(keys)}
                setPage={setPage}
                data={data}
                onSearch={onSearch}
                changeFilter={setFilterType.bind(this)}
                titles={titles}
                additionalTools={additionalTools}
            />
            <div className="box_tbl_list">
                <table className="table table-bordered table-hover">
                    <THead properties={properties}/>
                    {tableContent}
                </table>
                {tempTableContent}
            </div>
            <div className="box-pagination">
                <div className="pagination-wrapper">
                    {
                        !!searchedList.length &&
                        <Pagination
                            page={page}
                            setPage={setPage}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                            elementsAmount={searchedList.length}
                            handleChange={handleChange}
                        />
                    }
                    <div className="box_pagination_info">
                        {
                            searchedList.length
                                ? `${(page - 1) * 10 + 1 > searchedList.length ? searchedList.length : (page - 1) * 10 + 1} 
                                - ${page * 10 - 1 > searchedList.length ? searchedList.length : page * 10} of ${searchedList.length}`
                                : ''
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default SearchedList;
