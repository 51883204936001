import {GET_SALES} from "@actionTypes/salesActionTypes";

const initialState = null;

const salesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SALES:
            return action.payload;
        default:
            return state;
    }
};

export default salesReducer;
