import React, {createContext, useEffect, useState} from 'react';
import Header from "@UIComponents/Header/Header";
import {BrowserRouter, Route, Routes, useLocation, Navigate, useNavigate} from "react-router-dom";
import GamesList from "@gamePages/GamesList";
import InappsList from "@inappPages/InappsList";
import InappsPage from "@inappPages/InappsPage/InappsPage";
import Authorization from '@technicalPages/Authorization';
import Loading from "@UIComponents/Loading/Loading";
import Breadcrumbs from "@UIComponents/Breadcrumbs/Breadcrumbs";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import {
    CALLBACK_PATH, ERROR_PATH,
    GAMES_PATH, HOME_PATH,
    INAPP_EDITING_PATH,
    INAPPS_GROUPS_PATH, INAPPS_LIST_ALL_PATH, INAPPS_LIST_SALE_PATH,
    LOGIN_PATH,
    NEW_INAPP_PATH, NEW_SALE_PATH, SALE_INAPP_EDITING_PATH
} from "@constants/Paths";
import ErrorPage from "@technicalPages/ErrorPage";
import Notification from "@UIComponents/Notification/Notification";
import InappsCategories from "@inappPages/InappsCategories";
import SaleInapp from "@inappPages/InappsPage/SaleInappsPage";
import {useDispatch, useSelector} from "react-redux";
import getSales from "@actionCreators/salesActionCreator";
import getGames from "@actionCreators/gamesActionCreator";
import getTiers from "@actionCreators/tiersActionCreator";
import getInapps from "@actionCreators/inappsActionCreator";
import {ErrorBoundary} from "react-error-boundary";
import Fallback from "@helpers/Fallback";
import {GAME_QA_ROLE, MARKETING_QA_ROLE} from "@constants/ENV";

export const AppContext = createContext();

const AnimatedSwitch = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const technicalLocation = !window.location.pathname.includes("login");
    const group = localStorage.getItem('group');

    //Проверяю, есть ли креды при первом открытии проекта
    useEffect(() => {
        if (!localStorage.getItem('accessToken') && technicalLocation) navigate('/login');
    }, []);

    //Проверяю, есть ли креды при изменения localStorage
    window.addEventListener('storage', () => {
        if (!localStorage.getItem('accessToken') && technicalLocation) navigate('/login');
    })

    useEffect(() => {
        if (window.location.pathname.includes('login') && localStorage.getItem('accessToken')) navigate('/games');
    }, [window.location.pathname]);

    return (
        <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames="fade" timeout={600}>
                <Routes location={location}>
                    <Route
                        exact
                        path={LOGIN_PATH}
                        element={
                            <Authorization/>
                        }
                    />
                    <Route
                        exact
                        path={CALLBACK_PATH}
                        element={
                            <Loading
                                background='gray'
                                text="Authorization in your account"/>
                        }
                    />
                    <Route
                        exact
                        path={GAMES_PATH}
                        element={
                            <GamesList/>
                        }
                    ></Route>
                    <Route
                        exact
                        path={INAPPS_GROUPS_PATH}
                        element={
                            <InappsCategories/>
                        }
                    />
                    <Route
                        exact
                        path={INAPPS_LIST_ALL_PATH}
                        element={
                            group === MARKETING_QA_ROLE ?
                                <Fallback description="Sorry, you do not have sufficient permissions to view this page."/> :
                                <InappsList category={'all'}/>
                        }
                    />
                    <Route
                        exact
                        path={INAPPS_LIST_SALE_PATH}
                        element={
                            group === GAME_QA_ROLE ?
                                <Fallback description="Sorry, you do not have sufficient permissions to view this page."/> :
                                <InappsList category={'sales'}/>
                        }
                    />
                    <Route
                        exact
                        path={INAPP_EDITING_PATH}
                        element={
                            group === MARKETING_QA_ROLE ?
                                <Fallback description="Sorry, you do not have sufficient permissions to view this page."/> :
                                <InappsPage/>
                        }
                    />
                    <Route
                        exact
                        path={SALE_INAPP_EDITING_PATH}
                        element={
                            group === GAME_QA_ROLE ?
                                <Fallback description="Sorry, you do not have sufficient permissions to view this page."/> :
                                <SaleInapp/>
                        }
                    />
                    <Route
                        exact
                        path={NEW_INAPP_PATH}
                        element={
                            group === MARKETING_QA_ROLE ?
                                <Fallback description="Sorry, you do not have sufficient permissions to view this page."/> :
                                <InappsPage/>}
                    />
                    <Route
                        exact
                        path={NEW_SALE_PATH}
                        element={
                            group === GAME_QA_ROLE ?
                                <Fallback description="Sorry, you do not have sufficient permissions to view this page."/> :
                                <SaleInapp/>}
                    />
                    <Route
                        path={ERROR_PATH}
                        element={<ErrorPage/>}
                    />
                    <Route path={HOME_PATH} element={<Navigate to={GAMES_PATH}/>}/>
                    <Route path="*" element={<Navigate to={ERROR_PATH}/>}/>
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    )
};

function App() {
    const gameId = window.location.pathname.match(/gameid-([^/]+)/)?.[1];
    const [inapps, setInapps] = useState([]);
    const [sales, setSales] = useState([]);

    const credentials = useSelector(state => state.userData);
    const dispatch = useDispatch();

    const games = useSelector(state => state.games);
    const tiers = useSelector(state => state.tiers);

    const inappsList = useSelector(state => state.inapps);
    const salesList = useSelector(state => state.sales);

    useEffect(() => {
        if (credentials) {
            getGames(dispatch, credentials);
            getTiers(dispatch, credentials);
            if (gameId && window.location.pathname !== `/games/gameid-${gameId}/sales-inapps`) {
                getSales(dispatch, credentials);
            }
            if (gameId && window.location.pathname !== `/games/gameid-${gameId}/all-inapps`) {
                getInapps(dispatch, credentials, gameId);
            }
        }
    }, [credentials])

    useEffect(() => {
        setInapps(inappsList);
    }, [inappsList])

    useEffect(() => {
        setSales(salesList);
    }, [salesList])

    return (
        <BrowserRouter>
            <ErrorBoundary
                FallbackComponent={Fallback}
            >
                <AppContext.Provider value={{
                    credentials, dispatch, games, tiers,
                    inapps, setInapps, sales, setSales
                }}>
                        <Header/>
                        <Breadcrumbs/>
                        <AnimatedSwitch/>
                        <Notification/>
                </AppContext.Provider>
            </ErrorBoundary>
        </BrowserRouter>
    );
}

export default App;