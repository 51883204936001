import React, {useEffect, useState} from 'react';
import styles from './prices.module.css'
import {CURRENCIES} from "@constants/Currencies";
import getSortedCurrencies from "@utils/priceAndCurrencyMethods/getSortedCurrencies";

const TierCurrencyPrices = ({data}) => {
    const [result, setResult] = useState([]);
    const TierTemplate = ({i, c}) =>
        <React.Fragment key={i}>
            <div className={styles['currency-item']}>
                <div className={styles['currency-block']}>{c}</div>
                <div>{result[c] ? result[c] : '—'}</div>
            </div>
        </React.Fragment>

    useEffect(() =>{
        if (data) setResult(getSortedCurrencies(data.prices));
    }, [data])

    return (
        <div className={styles['tier-currency-wrapper']}>
            {Object.keys(result).length ?
                Object.keys(result).map((element, index) =>
                    <TierTemplate c={element} i={index}/>)
                :
                CURRENCIES.map((element, index) =>
                    <TierTemplate c={element} i={index}/>)
            }
        </div>
    );
};

export default TierCurrencyPrices;