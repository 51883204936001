import React from 'react';
import Button from "react-bootstrap/Button";
import {useNavigate, useParams} from "react-router-dom";
import {useFormikContext} from 'formik';
import submitData from "@inappPages/InappsPage/methods/submitData";
import {CONTENT_EDITOR_ROLE, MARKETING_QA_ROLE} from "@constants/ENV";

const SaleActionButtons = ({valuesChanged}) => {
    const group = localStorage.getItem('group');
    const {submitForm, values, isSubmitting} = useFormikContext();

    const onClickFunc = (method) => {
        submitData(values, method);
        submitForm();
    }

    if (values.state === 'OnReview' && group === CONTENT_EDITOR_ROLE) {
        {/*Отредактировать on review sale*/
        }
        return <Button
            className="action-block-btn submit"
            onClick={() => onClickFunc('UpdatePurchaseProductAlteredPriceDraft')}
            disabled={isSubmitting || !valuesChanged}
            type="submit">
            Save sale
        </Button>
    } else if (values.state === 'OnReview' && group === MARKETING_QA_ROLE) {
        {/*Перевести из onreview в ready*/
        }
        return <Button
            className="action-block-btn submit"
            onClick={() => onClickFunc('ApprovePurchaseProductAlteredPriceDraft')}
            disabled={isSubmitting}
            variant="warning"
            type="submit">
            Approve sale
        </Button>
    } else if (values.state === 'Active' && group === CONTENT_EDITOR_ROLE) {
        {/*Обновить активную скидку*/
        }
        return <Button
            className="action-block-btn submit"
            onClick={() => onClickFunc('CreatePurchaseProductAlteredPriceDraftFromActive')}
            disabled={isSubmitting}
            type="submit">
            Save sale
        </Button>
    }
    if (!values.state && group === CONTENT_EDITOR_ROLE) {
        {/*Создать новую скидку*/
        }
        return <Button
            className="action-block-btn submit"
            onClick={() => onClickFunc('CreatePurchaseProductAlteredPriceDraft')}
            disabled={isSubmitting}
            type="submit">
            Save sale
        </Button>
    }
};

const ActionButtonsWithCancel = ({valuesChanged, category}) => {
    const {gameId} = useParams();
    const navigate = useNavigate();
    const {isSubmitting} = useFormikContext();
    return (
        <>
            <SaleActionButtons
                valuesChanged={valuesChanged}
                category={category}
            />
            <Button
                disabled={isSubmitting}
                className="action-block-btn cancel"
                onClick={() => navigate(`/games/gameid-${gameId}/sales-inapps`)}
                variant="outline-primary">
                Cancel
            </Button>
        </>
    )
}

export default ActionButtonsWithCancel;