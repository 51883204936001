import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import './pages/styles/index.css';
import './pages/styles/variables.css';
import './pages/styles/keyframes.css';
import App from "./App";
import {store} from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
        <Provider store={store}>
                <App />
        </Provider>
    // </React.StrictMode>
);

