import axios from "axios";
import {API_VERSION} from "@constants/API";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import refreshToken from "@components/API/refreshToken";
import {API_HOST} from "@constants/ENV";
import axiosInherit from "axios-inherit";

const instance = axiosInherit(axios);
function publishSale (obj) {
    obj.accessToken = localStorage.getItem('accessToken');
    return instance({
        method: 'post',
        url: `https://${API_HOST}/${API_VERSION}/PublishPurchaseProductAlteredPriceDraft`,
        data: new URLSearchParams(obj)
    })
        .then(function (response) {
            return response;
        })
}

createAuthRefreshInterceptor(instance, refreshToken, {
    statusCodes: [403]
});

export default publishSale;

