import React from 'react';
import styles from './AuthorizationCall.module.css'
import Button from "react-bootstrap/Button";
import handleAzureUrl from "@reduxServices/handleMicrosoftAzure";

const AuthorizationCall = () => {
    return (
        <div className={styles.authWrapper}>
            <Button
                className={styles.authBtn}
                onClick={handleAzureUrl}
                variant="primary">

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0H16V16H0V0Z" fill="#F3F3F3"/>
                    <path d="M0.695801 0.695648H7.65232V7.65217H0.695801V0.695648Z" fill="#F35325"/>
                    <path d="M8.34766 0.695648H15.3042V7.65217H8.34766V0.695648Z" fill="#81BC06"/>
                    <path d="M0.695801 8.34782H7.65232V15.3043H0.695801V8.34782Z" fill="#05A6F0"/>
                    <path d="M8.34766 8.34782H15.3042V15.3043H8.34766V8.34782Z" fill="#FFBA08"/>
                </svg>

                Login with Microsoft
            </Button>
            <div>
                Please use your g5.com email to login
            </div>
        </div>
    );
};

export default AuthorizationCall;
