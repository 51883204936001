import {GET_INAPPS} from "@actionTypes/inappsActionTypes";

const initialState = null;

const inappsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INAPPS:
            return action.payload;
        default:
            return state;
    }
};

export default inappsReducer;
