import React, {useContext, useEffect, useState} from 'react';
import Title from "@UIComponents/Title/Title";
import SearchedList from "@UIComponents/SearchedList/SearchedList";
import AddNewInappBtn from "@inappListComponents/AddNewInappBtn/AddNewInappBtn";
import {useLocation, useParams} from "react-router-dom";
import ModalComponent from "@UIComponents/Modal/ModalComponent";
import moment from "moment";
import useGameName from "@hooks/useGameName";
import findPriceWithTier from "@utils/priceAndCurrencyMethods/findPriceWithTier";
import {AppContext} from "../../App";
import {useSelector} from "react-redux";
import getInapps from "@actionCreators/inappsActionCreator";
import getSales from "@actionCreators/salesActionCreator";
import {CONTENT_EDITOR_ROLE} from "@constants/ENV";


const InappsList = ({category}) => {
    const {credentials, setInapps, dispatch} = useContext(AppContext);

    const {gameId} = useParams();
    const location = useLocation();
    const [orderedData, setOrderedData] = useState(null);
    const [loading, setLoading] = useState(true);
    const {tiers} = useContext(AppContext);
    const [canAddSale, setCanAddSale] = useState(false);

    const inapps = useSelector(state => state.inapps);
    const sales = useSelector(state => state.sales);
    const gameName = useGameName();
    const keys = ['title', 'id'];

    const titles = 'title';
    const headTitles = ['In-app Id', 'Price', 'Tier', 'Title', 'Status', 'Production'];
    const headTitlesSales = ['In-app Id', 'Price', 'Dates', 'In-app Title', 'Status', 'Production'];
    const now = moment();
    const roleWithoutQA = localStorage.getItem('group') === CONTENT_EDITOR_ROLE;

    useEffect(() => {
        if (location.state && location.state.gameName) {
            localStorage.setItem('gameName', location.state.gameName);
            window.dispatchEvent(new Event('storage'));
        }
    }, [location.state])

    useEffect(() => {
        if (credentials) {
            setLoading(false);
            getInapps(dispatch, credentials, gameId);
            getSales(dispatch, credentials);
        }
    }, [credentials])

    useEffect(() => {
        if (inapps) setInapps(inapps);
        if (inapps && tiers && category === 'all'){
            setOrderedData([]);
            inapps.forEach((inapp) => {
                setOrderedData((prevState) => [...prevState, {id: inapp.id, price: /*sale.prices.USD*/findPriceWithTier(tiers, inapp.priceTier, inapp.id), priceTier: inapp.priceTier,
                    title: inapp.titles.en, state: inapp.state, hasActivePurchase: inapp.hasActivePurchase}])
            })
        }
    }, [inapps, tiers])

    useEffect(() => {
        if (inapps && sales && tiers && category === 'sales' && !loading){
            setOrderedData([]);
            setCanAddSale(!!inapps.find(({ hasActivePurchase, state }) => hasActivePurchase && state !== 'Ready'));
            sales.forEach((sale) => {
                if (sale.id.startsWith(gameId + '.') || sale.xpromoId === gameId){
                    const originalInapp = inapps.filter((i) => i.id === sale.id)[0];

                    setOrderedData((prevState) => [...prevState, {
                        id: sale.id, price: findPriceWithTier(tiers, originalInapp?.priceTier, sale.id),
                        dates: {
                            date: `${moment(sale.startTime).format("DD.MM.YYYY")} — ${moment(sale.endTime).format("DD.MM.YYYY")}`,
                            warningSign: now.valueOf() > moment(sale.endTime).valueOf()
                        },  title: originalInapp?.titles.en || '', state: sale.state, hasActivePurchase: sale.hasActivePurchase
                    }])
                }
            })
        }
    }, [sales, inapps, tiers, loading])

    return (
            <div className="mainContent">
                <div className="inapp-header-block">
                    <Title mainText={category === 'all' ? 'All in-apps:' : 'All sales:'}
                           secondaryText={gameName ? gameName.replace(/(\s*:\s*)/, '・') : ''}/>
                    <div className={'actions-block-inapps'}>
                        {
                            category === 'all' && roleWithoutQA &&
                            <AddNewInappBtn styles={'primary'}/>
                        }
                        {
                            category === 'sales' && roleWithoutQA &&
                            <AddNewInappBtn styles={'primary'}
                                            label={'Add sale'}
                                            pathPart={'/new-sale'}
                                            disabled={!canAddSale}
                            />
                        }
                        {
                            roleWithoutQA && <ModalComponent category={category}/>
                        }
                    </div>
                </div>
                <div className="table-wrapper">
                    <SearchedList
                        data={orderedData}
                        keys={keys}
                        titles={titles}
                        canAddSale={canAddSale}
                        headTitles={category === 'all' ? headTitles : headTitlesSales}
                        noResultTitle={category === 'all' ? 'There are no in-apps yet' : 'There no sales yet.'}
                        addNewInappBtn={true}
                        category={category}
                        additionalTools={'filter'}/>
                </div>
            </div>
    );
};

export default InappsList;
