import React from 'react';
import Button from "react-bootstrap/Button";
import {useNavigate, useParams} from "react-router-dom";
import { useFormikContext } from 'formik';
import submitData from "@inappPages/InappsPage/methods/submitData";
import useInappStatus from "@hooks/useInappStatus";
import {CONTENT_EDITOR_ROLE, GAME_QA_ROLE} from "@constants/ENV";

const ActionButtons = ({valuesChanged}) => {
    const group = localStorage.getItem('group');
    const status = useInappStatus();
    const {inappId} = useParams();
    const { submitForm, values, isSubmitting } = useFormikContext();

    const onClickFunc = (method) => {
        submitData(values, method);
        submitForm();
    }

    if (inappId) {
        if (status === 'Active') {
            if (group === CONTENT_EDITOR_ROLE) {
                {/*Перевести из active в on review*/}
                return <Button
                        className="action-block-btn submit"
                        onClick={() => onClickFunc('createPurchaseProductDraft')}
                        disabled={isSubmitting || !valuesChanged}
                        type="submit">
                        Save in-app
                    </Button>
            }
        } else if (status === 'OnReview') {
            if (group === CONTENT_EDITOR_ROLE) {
                {/*Отредактировать on review inapp*/}
                return <Button
                        className="action-block-btn submit"
                        onClick={() => onClickFunc('updatePurchase')}
                        disabled={isSubmitting || !valuesChanged}
                        type="submit">
                        Save in-app
                    </Button>
            } else if (group === GAME_QA_ROLE) {
                {/*Перевести из onreview в ready*/}
                return <Button
                        className="action-block-btn submit"
                        onClick={() => onClickFunc('updatePurchaseStatus')}
                        disabled={isSubmitting}
                        variant="warning"
                        type="submit">
                        Approve in-app
                    </Button>
            }
        }
    } else {
        if (group === CONTENT_EDITOR_ROLE) {
            {/*Создать новый инап*/}
            return <Button
                    className="action-block-btn submit"
                    onClick={() => onClickFunc('createPurchase')}
                    disabled={isSubmitting}
                    type="submit">
                    Save in-app
                </Button>
        }
    }
};
const ActionButtonsWithCancel = ({valuesChanged}) => {
    const {gameId} = useParams();
    const navigate = useNavigate();
    const { isSubmitting } = useFormikContext();
    return (
        <>
            <ActionButtons
                valuesChanged={valuesChanged}
            />
            <Button
                disabled={isSubmitting}
                className="action-block-btn cancel"
                onClick={() => navigate(`/games/gameid-${gameId}/all-inapps`)}
                variant="outline-primary">
                Cancel
            </Button>
        </>
    )
}

export default ActionButtonsWithCancel;