import React, {useContext, useEffect, useState} from 'react';
import {Modal, Button, Spinner} from 'react-bootstrap';
import styles from './ModalComponent.module.css'
import {useNavigate, useParams} from "react-router-dom";
import publishCall from "@reduxServices/publishPurchase";
import jsonParsedData from "@hooks/useJsonParsedData";
import {AppContext} from "../../../App";
import publishSale from "@reduxServices/publishSale";

export const ModalComponent = ({category}) => {
    const [modalState, setModalState] = useState("close");
    const [readyInapps, setReadyInapps] = useState([]);
    const navigate = useNavigate();
    const {gameId} = useParams();
    const {inapps, sales} = useContext(AppContext);

    useEffect(() => {
        if (category === 'all' && inapps){
            let readyInappsTemp = inapps.filter(obj => {
                return obj.state === 'Ready'
            })
            setReadyInapps(readyInappsTemp)
        }
    }, [inapps])

    useEffect(() => {
        if (category === 'sales' && sales){
            let readySalesTemp = sales.filter(({state, id, xpromoId}) => {
                return state === 'Ready' && (id.startsWith(gameId + '.') || xpromoId === gameId)
            })
            setReadyInapps(readySalesTemp)
        }
    }, [sales])

    const PublishPurchase = (inapps) => {
        // Публикация инапа
        inapps.forEach(function (item) {
            if (category === 'all'){
                publishCall(jsonParsedData({id: item.id})).then(() => {
                    setModalState('modal-three');
                })
            } else if (category === 'sales'){
                publishSale(jsonParsedData({id: item.id})).then(() => {
                    setModalState('modal-three');
                })
            }
        });
    };

    function handleShowModalOne (event) {
        setModalState("modal-one");
        event.currentTarget.blur();
    }

    const handleShowModalTwo = (event) => {
        setModalState("modal-two");
        event.currentTarget.blur();
    }

    const handleDeploy = (event) => {
        setModalState("loading");
        PublishPurchase(readyInapps);
        event.currentTarget.blur();
    }

    const handleClose = (event) => {
        setModalState("close");
        navigate(`/games`);
        event.currentTarget.blur();
    }

    return (
        <div>
            <Button
                disabled={readyInapps.length === 0}
                variant="success"
                onClick={handleShowModalOne}>
                Deploy changes
            </Button>

            <Modal
                centered
                onHide={() => setModalState("close")}
                show={modalState === "modal-one"}>
                <Modal.Header closeButton>
                    <Modal.Title className={styles.modalTitle}>
                        1/2. Deploy changes?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-secondary"
                        onClick={(event) => {
                            setModalState("close");
                            event.currentTarget.blur();
                        }}>
                        Close
                    </Button>
                    <Button
                        variant="success"
                        className={styles.modalBtn}
                        onClick={handleShowModalTwo}>Deploy changes</Button>
                </Modal.Footer>
            </Modal>
            {/************* Modal 2 ***************/}
            <Modal
                centered
                onHide={() => setModalState("close")}
                show={modalState === "modal-two" || modalState === "loading"}>
                <Modal.Header closeButton>
                    <Modal.Title className={styles.modalTitle}>
                        2/2. Are you sure?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer className={styles.modalFooter}>
                    <Button
                        variant="outline-secondary"
                        onClick={(event) => {
                            setModalState("close");
                            event.currentTarget.blur();
                        }}>
                        Close
                    </Button>
                    <Button
                        disabled={modalState === 'loading'}
                        variant="success"
                        className={styles.modalBtn}
                        onClick={handleDeploy}>
                        {
                            {
                                'modal-two': "Yes, deploy",
                                'loading': <Spinner className={styles.spinnerWrapper}
                                                    animation="border"
                                                    variant="light"/>
                            }[modalState]
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
            {/***************** Modal 3 *****************/}
            <Modal
                centered
                onHide={() => setModalState("close")}
                show={modalState === "modal-three"}>
                <Modal.Header closeButton>
                    <Modal.Title className={styles.modalTitle}>
                        Changes were successfully deployed!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer className={styles.modalFooterFlexEnd}>
                    <Button
                        variant="primary"
                        className={styles.modalBtn}
                        onClick={handleClose}>Go to games list</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


export default ModalComponent;