import React from "react";
import TCell from "./TCell";

const TBody = ({data}) => {
    return (
        <tbody>
        {
            data.map((el, i) =>
                <TCell key={i} element={el} />
            )
        }
        </tbody>
    )
}

export default TBody;