import React, {useContext, useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import {ErrorMessage, useFormikContext} from "formik";
import {useParams} from "react-router-dom";
import Select from "react-select";
import styles from './InappId.module.css'
import {AppContext} from "../../../App";
import {useSkipInappValidation} from "@hooks/useSkipInappValidation";

const InappIdComponent = ({selectedInappId, setSelectedInappId, index, newTier}) => {
    const {gameId, inappId} = useParams();
    const skipValidation = useSkipInappValidation();
    const [canAddSaleData, setCanAddSaleData] = useState([]);

    const {values, setFieldValue, errors, touched} = useFormikContext();

    const {inapps, sales} = useContext(AppContext);

    const handleChangeInappId = (e) => {
        setSelectedInappId(e);
        setFieldValue('id', skipValidation ? e.value : gameId + '.' + e.value);
    }

    useEffect(() => {
        setFieldValue('priceTier', newTier);
    }, [newTier])

    useEffect(() => {
        if (inapps) {
            setCanAddSaleData([]);

            if (sales && !inappId) {
                const salesIdsSet = new Set(sales.map(({id}) => id));
                const canAddSaleData = inapps
                    .filter(({id, hasActivePurchase}) => !salesIdsSet.has(id) && hasActivePurchase)
                    .map((sale, index) => ({
                        id: index,
                        value: sale.id.replace(gameId + '.', ''),
                        label: sale.id.replace(gameId + '.', ''),
                    }));
                setCanAddSaleData((prevState) => [...prevState, ...canAddSaleData]);
            } else if (inappId) {
                setCanAddSaleData([{
                    id: 0,
                    value: inappId.replace(gameId + '.', ''),
                    label: inappId.replace(gameId + '.', '')
                }])
            }

        }
    }, [inapps, sales])

    return (
        <div className={styles['inapp-id-wrapper']}>
            <div className="form-title">In-app Id</div>
            <div className={styles['inputs-wrapper']}>
                {!skipValidation &&
                    <Form.Label
                    className={styles['left-form-control']}
                >{`${gameId + '.'}`}</Form.Label>
                }
                <div className={styles['input-block']}>
                    <Select
                        onChange={(e) => handleChangeInappId(e)}
                        className={`${styles['inapp-select']} ${
                            touched.id && errors.id ? "is-invalid" : ""
                        }`}
                        classNamePrefix="react-select"
                        options={canAddSaleData}
                        placeholder={'Choose in-app'}
                        value={inappId ? canAddSaleData.find(({value}) => value === values.id.replace(gameId + '.', '')) : selectedInappId}
                        isDisabled={!!inappId}
                        name={index}
                        styles={{
                            dropdownIndicator: (provided, state) => ({
                                ...provided,
                                transform: state.selectProps.menuIsOpen && "rotate(180deg)"
                            })
                        }}
                    />
                    <ErrorMessage
                        component="div"
                        name='id'
                        className="invalid-feedback"
                    />
                </div>
            </div>
        </div>
    );
};

export default InappIdComponent;