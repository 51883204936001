import React, {useState, useEffect, useContext} from "react";
import {CURRENCIES_OBJECT} from "@constants/Currencies";
import {AppContext} from "../App";
import {useSkipInappValidation} from "@hooks/useSkipInappValidation";

export default function useSale(gameId, inappId, selectedInappId) {
    const saleDefault = {
        id: '',
        priceTier: '',
        salePrices: CURRENCIES_OBJECT,
        salePriceTier: '',
        startTime: '',
        endTime: '',
        startTimeHours: new Date().setUTCHours(11, 0),
        endTimeHours: new Date().setUTCHours(11, 0),
        state: ''
    }

    const skipValidation = useSkipInappValidation();
    const {sales, inapps} = useContext(AppContext);
    const [temp, setTemp] = useState(saleDefault);

    useEffect(() => {
        if (sales && inapps) {
            if (inappId) {
                const sale = sales.find(sale => sale.id === inappId);
                if (sale) {
                    const inapp = inapps.find(item => item.id === inappId);
                    setTemp(prevTemp => ({
                        ...prevTemp,
                        ...sale,
                        priceTier: inapp ? inapp.priceTier : "",
                        salePrices: sale.prices,
                        startTimeHours: new Date(sale.startTime).setUTCHours(new Date(sale.startTime).getUTCHours(), new Date(sale.startTime).getUTCMinutes()),
                        endTimeHours: new Date(sale.endTime).setUTCHours(new Date(sale.endTime).getUTCHours(), new Date(sale.endTime).getUTCMinutes())
                    }));
                } else {
                    const inapp = inapps.find(item => item.id === inappId);
                    setTemp(prevTemp => ({
                        ...prevTemp,
                        id: inapp ? inapp.id : "",
                        priceTier: inapp ? inapp.priceTier : ""
                    }));
                }
            }
            if (selectedInappId) {
                const inapp = inapps.find(
                    skipValidation ? item => item.id === selectedInappId.value :
                            item => item.id === gameId + "." + selectedInappId.value
                );
                setTemp(prevTemp => ({
                    ...prevTemp,
                    id: inapp ? inapp.id : "",
                    priceTier: inapp ? inapp.priceTier : ""
                }));
            }
        }
    }, [sales, inapps, selectedInappId]);

    return temp;
}
