import {useContext, useEffect, useMemo, useState} from 'react';
import {AppContext} from "../App";

export default function UseGameName () {
    const {games} = useContext(AppContext);

    const gameId = useMemo(() => {
        return window.location.pathname.match(/gameid-([^/]+)/)?.[1];
    }, [window.location.pathname]);

    const [gameName, setGameName] = useState('');

    useEffect(() => {
        if (gameId && games) {
            const game = games.find((g) => g.xpromoId === gameId);
            if (game) setGameName(game.name);
        }
    }, [games, gameId]);

    return gameName;
}
