import React from "react";

const THead = ({properties}) => {
    return (
        <thead>
        <tr>
            {properties.map((p, i) => <td key={i}>{p}</td> )}
        </tr>
        </thead>
    );
}

export default THead;