export const INAPPS_NAVIGATION_NEW_INAPP =[
    {title: 'Localizations', anchor: '#localization'},
    {title: 'Price', anchor: '#prices'},
    {title: 'In-app Id', anchor: '#xpromo'}];

export const INAPPS_NAVIGATION_INAPP_EDITING =[
    {title: 'Localizations', anchor: '#localization'},
    {title: 'Price', anchor: '#prices'}];

export const SALE_NAVIGATION =[
    {title: 'In-app info', anchor: '#xpromo'},
    {title: 'Sale', anchor: '#prices'}];