import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form'
import styles from './InputBlock.module.css'
import {ErrorMessage, Field, useFormikContext} from "formik";
import {CONTENT_EDITOR_ROLE} from "@constants/ENV";

const InputBlock = ({
                        groupLabel, inputLabel, index, errors, value, status, labelClass = '',
                        placeholder = '', componentRender = 'input', name, sign,
                        fieldsToDisplay, fieldsToDisplayIndex, setFieldsToDisplay, disabled = false, setSalePrice
                    }) => {
    const { handleChange, handleBlur, isSubmitting, values, setFieldValue } = useFormikContext();
    const [validationError, setValidationError] = useState(index ? !!(errors && errors[index] && errors[index].length) : /*!!errors*/null)
    const [valBeforeSubmission, setValBeforeSubmission] = useState(null);

    const disabledByRole = status === "Ready" || localStorage.getItem('group') !== CONTENT_EDITOR_ROLE;
    const valueError = !valBeforeSubmission?.trim() && valBeforeSubmission !=null;

    useEffect(() => {
        if (isSubmitting) {
            setValBeforeSubmission(value ? value+'' : '');
            setValidationError(index ? !!(errors && errors[index] && errors[index].length) : null);
        };
    }, [isSubmitting])

    return (
        <>
            {groupLabel && <Form.Label className={labelClass}>{groupLabel}</Form.Label>}
            <Form.Group>
                {inputLabel && <Form.Label className={labelClass}>{inputLabel}</Form.Label>}
                <div
                    className={sign ? `${styles.inputSignGroup} ${/*validationError*/errors?.[index] ? "is-invalid" : ""}` : ``}>
                    <Field
                        component={componentRender}
                        name={index ? `${name}.${index}` : `${name === 'titles' || name === 'descriptions' ? `test` : name}`}
                        value={value}
                        type="text"
                        placeholder={placeholder}
                        disabled={disabledByRole || disabled}
                        onInput={() => {
                            if ('salePriceTier' in values) {
                                setFieldValue('salePriceTier', null);
                                setSalePrice(null);
                            }
                        }}
                        onChange={(e) => {
                            handleChange(e);
                            if (fieldsToDisplay) {
                                let additionalArray = [...fieldsToDisplay];
                                if (name === 'titles') additionalArray[fieldsToDisplayIndex].title = e.target.value;
                                if (name === 'descriptions') additionalArray[fieldsToDisplayIndex].description = e.target.value;
                                if (setFieldsToDisplay) setFieldsToDisplay(additionalArray);
                            }
                        }}
                        onBlur={handleBlur}
                        className={sign ?
                            `form-control ${styles.inputWithSign} ${/*validationError*/errors?.[index] ? "is-invalid" : ""}` :
                            `form-control form-control-inapps ${valueError || valBeforeSubmission?.length>100 ? "is-invalid" : ""
                            } ${styles.formControl}`}
                    />
                    {sign &&
                        <div className='labelBlock'>
                            <span>{sign}</span>
                        </div>}
                </div>
                {/*Ошибки ниже на тот случай, когда locale code пустой и локаль не добавилась в формик*/}
                {/*Валидирую все вручную*/}
                {
                    !sign && valueError && !valBeforeSubmission?.length &&
                    <div className="warning-message">This field is required</div>
                }
                {
                    !sign && valueError && !!valBeforeSubmission?.length &&
                    <div className="warning-message">This field cannot contain only whitespaces</div>
                }
                {
                    !sign && valBeforeSubmission?.length>100 &&
                    <div className="warning-message">You have reached the character limit of 100 symbols</div>
                }
                <ErrorMessage
                    component="div"
                    name={index ? `${name}.${index}` : `${name === 'titles' || name === 'descriptions' ? `test` : name}`}
                    className="invalid-feedback"
                />
            </Form.Group>
        </>
    )
}

export default InputBlock;

