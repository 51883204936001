import React, {useEffect, useState} from 'react';
import Toast from 'react-bootstrap/Toast';

function ToastSuccess({status, text}) {
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow(!show);

    useEffect(() => {
        if (status) setShow(true)
    }, [status])

    return (
        <Toast show={show} onClose={toggleShow} bg={'success'} delay={5000} autohide>
            <Toast.Header>
                <strong className="me-auto">{"Success"}</strong>
            </Toast.Header>
            <Toast.Body>{text}</Toast.Body>
        </Toast>
    );
}

export default ToastSuccess;