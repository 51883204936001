import {useParams} from "react-router-dom";
import useInapp from "./useInapp";

export default function UseInappStatus () {
    const {inappId} = useParams();
    const {gameId} = useParams();
    const oneInapp = useInapp(gameId, inappId);

    return oneInapp.state;
};
