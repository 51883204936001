import {CURRENCIES} from "@constants/Currencies";

const GetSortedCurrencies = (pricesObject) => {
    let f = new Set();
    let result = {};

    CURRENCIES.forEach((t) => {
        for (let key of Object.keys(pricesObject)) {
            if (!CURRENCIES.includes(key) && !f.has(key)) {
                f.add(key);
                result = {...result, [key]: pricesObject[key]}
            } else if (t === key) {
                result = {...result, [key]: pricesObject[key]}
                return;
            }
        }
    })
    return result;
};

export default GetSortedCurrencies;