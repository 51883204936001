import React from 'react';
import Form from "react-bootstrap/Form";
import styles from './InappId.module.css'
import {ErrorMessage, useFormikContext} from "formik";
import {useParams} from "react-router-dom";
import {useSkipInappValidation} from "@hooks/useSkipInappValidation";

const InappIdComponent = ({index}) => {
    const {values, errors, touched, handleChange, handleBlur} = useFormikContext();
    const {gameId} = useParams();
    const skipValidation = useSkipInappValidation();

    return (
        <div>
            <div>
                <h2 className="form-title required">In-app Id</h2>
                <div className={styles['inputs-wrapper']}>
                    {skipValidation ?
                        <div className={styles['input-block']}>
                            <Form.Control
                                name={index}
                                value={values.id}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                    values.id = e.target.value;
                                }}
                                className={`form-control ${
                                    touched.id && errors.id ? "is-invalid" : ""
                                } ${styles.inappInput}`}
                                type="text"
                                placeholder="name"
                            />
                            <ErrorMessage
                                component="div"
                                name='id'
                                className="invalid-feedback"
                            />
                        </div> :
                        <>
                            <Form.Label
                                className={styles['left-form-control']}
                            >{`${gameId + '.'}`}</Form.Label>
                            <div className={styles['input-block']}>
                                <Form.Control
                                    name={index}
                                    value={values.id}
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        handleChange(e);
                                        values.id = gameId + '.' + e.target.value;
                                    }}
                                    className={`form-control ${
                                        touched.id && errors.id ? "is-invalid" : ""
                                    } ${styles.inappInput} ${styles['right-form-control']}`}
                                    type="text"
                                    placeholder="name"
                                />
                                <ErrorMessage
                                    component="div"
                                    name='id'
                                    className="invalid-feedback"
                                />
                            </div>
                        </>}

                </div>
            </div>
            <div className={styles['warning-block']}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M10.7088 3.74975L2.45878 17.9997C2.32735 18.2274 2.25805 18.4856 2.25781 18.7484C2.25758 19.0113 2.32643 19.2696 2.45745 19.4975C2.58848 19.7253 2.77709 19.9148 3.00438 20.0468C3.23168 20.1788 3.48967 20.2488 3.75253 20.2497H20.2525C20.5154 20.2488 20.7734 20.1788 21.0007 20.0468C21.228 19.9148 21.4166 19.7253 21.5476 19.4975C21.6786 19.2696 21.7475 19.0113 21.7472 18.7484C21.747 18.4856 21.6777 18.2274 21.5463 17.9997L13.2963 3.74975C13.1658 3.52181 12.9775 3.33238 12.7503 3.20064C12.5231 3.0689 12.2652 2.99951 12.0025 2.99951C11.7399 2.99951 11.4819 3.0689 11.2548 3.20064C11.0276 3.33238 10.8392 3.52181 10.7088 3.74975Z"
                        fill="#FFC107" stroke="#FFC107" stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                        d="M12.375 14.9998C11.7321 14.9998 11.25 15.4819 11.25 16.1248C11.25 16.7676 11.7321 17.2498 12.375 17.2498C13.0179 17.2498 13.5 16.7676 13.5 16.1248C13.5 15.4819 13.0179 14.9998 12.375 14.9998Z"
                        fill="#212529"/>
                    <path
                        d="M12.9136 13.8748L13.3421 9.37476C13.4493 8.78547 13.0207 8.24976 12.3778 8.24976C11.735 8.24976 11.3064 8.7319 11.36 9.37476L11.7886 13.8748H12.9136Z"
                        fill="#212529"/>
                </svg>
                Please note: You won't be able to change In-app Id after saving in-app!
            </div>
        </div>
    );
};

export default InappIdComponent;