import * as Yup from "yup";
import {CURRENCIES} from "@constants/Currencies";

const SaleValidation = () => {
    const maxDate = '12/31/2040 23:59:59';
    const patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/;

    const createCurrencyField = () => Yup.number()
        .required("This field is required")
        .typeError('Use only digits (0-9) and one dot (.)')
        .positive('Price should be greater than 0')
        .noWhitespace()
        .accuracyTwoDigits();

    function isInteger(msg) {
        return this.test({
            name: 'isInteger',
            exclusive: false,
            message: msg || "Value should be an integer",
            test: function (value, valueObj) {
                return !String(valueObj.originalValue).includes('.');

            },
        });
    }

    function accuracyTwoDigits(msg) {
        return this.test({
            name: 'accuracyTwoDigits',
            exclusive: false,
            message: msg || "The price must be decimal with two digits accuracy (ex. 0.99)",
            test: (val, valueObj) => {
                if (valueObj.originalValue !== undefined) {
                    return patternTwoDigitsAfterComma.test(valueObj.originalValue);
                }
                return true;
            }
        })
    }

    function greaterThan(ref, type, msg) {
        return this.test({
            name: 'greaterThan',
            exclusive: false,
            message: msg || 'Should be later than start date',
            params: {
                reference: ref.path
            },
            test: function(value) {
                if (type === 'ref') {
                    const date1 = Date.parse(value) ? Date.parse(value) : +value;
                    const date2 = Date.parse(this.resolve(ref)) ? Date.parse(this.resolve(ref)) : +this.resolve(ref);
                    return date1 > date2;
                } else if (type === 'digit') {
                    return +value > +ref;
                }
            }
        })
    }

    function lowerThan(date, msg) {
        return this.test({
            name: 'lowerThan',
            message: msg || 'Invalid dates range',
            test: (value) => {
                const limitDate = new Date(date);
                const limitDateMilliseconds = limitDate.getTime();
                return +value < limitDateMilliseconds
            }
        })
    }

    function noWhitespace() {
        return this.transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value));
    }

    Yup.addMethod(Yup.string, 'greaterThan', greaterThan);
    Yup.addMethod(Yup.string, 'lowerThan', lowerThan);
    Yup.addMethod(Yup.number, 'accuracyTwoDigits', accuracyTwoDigits);
    Yup.addMethod(Yup.number, 'isInteger', isInteger);
    Yup.addMethod(Yup.number, 'noWhitespace', noWhitespace);

    return Yup.object().shape({
        id: Yup.string()
            .required("This field is required"),
        salePriceTier: Yup.string()
            .nullable(),
        salePrices: Yup.object().shape(
            CURRENCIES.reduce((acc, currency) => {
                acc[currency] = createCurrencyField();
                return acc;
            }, {
                JPY: Yup.number()
                    .required("This field is required")
                    .typeError('Use only digits (0-9)')
                    .positive('Price should be greater than 0')
                    .noWhitespace()
                    .isInteger("Use only digits (0-9)")
            })
        ),
        startTime: Yup.string().required("This field is required")
                .lowerThan(maxDate)
                .nullable(),
        endTime: Yup.string().required("This field is required")
                .lowerThan(maxDate)
                .greaterThan(Yup.ref('startTime'), 'ref')
                .nullable(),
        startTimeHours : Yup.string().required("This field is required")
            .nullable(),
        endTimeHours : Yup.string().required("This field is required")
            .nullable(),
    });
};

export default SaleValidation;