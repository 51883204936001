import React, {useEffect, useState} from "react";
import InputBlock from "@UIComponents/InputBlock/InputBlock";
import {Button, Form} from "react-bootstrap";
import {ErrorMessage, Field, useFormikContext} from "formik";
import styles from './locale.module.css'

const LocaleTemplate = ({
                            elemKey, index, addedItem, disabled,
                            fieldsToDisplay, setFieldsToDisplay, required = false
                        }) => {
    const {values, errors, touched, setFieldValue} = useFormikContext();
    const [removedItem, setRemovedItem] = useState(null);
    const [input, setInput] = useState(elemKey);

    const validationError = errors.languageCode ? !!(errors.languageCode && errors.languageCode[index]) : null;

    useEffect(() => {
        setFieldValue(`languageCode`, {});
        fieldsToDisplay.map((f, i) => {
            if (f) setFieldValue(`languageCode.${i}`, f.key);
        })
    }, [fieldsToDisplay])

    const removeLocale = () => {
        setRemovedItem(elemKey);
        setTimeout(() => {
            //Удаляю в FieldsToDisplay удаленную локаль
            let newArr = [...fieldsToDisplay];
            delete newArr[index];
            setFieldsToDisplay(newArr);

            //Удаляю в формике
            setFieldValue(`titles.${elemKey}`);
            setFieldValue(`descriptions.${elemKey}`);

            for (const [index, field] of newArr.entries()) {
                if (field && field.key && field.key === elemKey) {
                    setFieldValue(`titles.${elemKey}`, newArr[index].title);
                    setFieldValue(`descriptions.${elemKey}`, newArr[index].description);
                    return;
                }
            }
            setRemovedItem(null);
        }, 800);
    }

    const addLocale = () => {
        //Добавляю локали в формик
        let keysArray = [];
        fieldsToDisplay.map((k, i) => {
            if (k && k.key) {
                keysArray.push(k.key)
                //Если такой локали еще нет, то добавляю в схему
                //Проверяю, чобы не перезаписать старую локаль
                if (fieldsToDisplay[i].key) {
                    setFieldValue(`titles[${fieldsToDisplay[i].key}]`, fieldsToDisplay[i].title);
                    setFieldValue(`descriptions[${fieldsToDisplay[i].key}]`, fieldsToDisplay[i].description);
                }
            }
        })

        //Удаляю лишние (переназванные) локали
        Object.keys(values.titles).map((k) => {
            if (keysArray.indexOf(k) === -1) {
                setFieldValue(`titles.${k}`);
                setFieldValue(`descriptions.${k}`);
            }
        })
    }

    const changeLocale = (event) => {
        setFieldValue(`languageCode.${index}`, event.target.value);
        setInput(event.target.value);

        let newArr = [...fieldsToDisplay];
        newArr[index].key = event.target.value;
        setFieldsToDisplay(newArr);
    }

    return (<div key={index}
                 className={`localization-grid list-container 
                 ${elemKey === removedItem ? 'removed-item' : ''} 
                 ${(elemKey === addedItem && index === fieldsToDisplay.length - 1) ? 'restored-item' : ''}`}>
            <div>
                <Form.Label>Locale code</Form.Label>
                <Field value={input}
                       name={`languageCode.${index}`}
                       className={`form-control ${validationError ? "is-invalid" : ""}`}
                       onBlur={addLocale}
                       onChange={(event) => changeLocale(event)}
                       disabled={required || disabled}>
                </Field>
                <ErrorMessage
                    component="div"
                    name={`languageCode.${index}`}
                    className="invalid-feedback"
                />
            </div>
            <InputBlock value={fieldsToDisplay[index] ? fieldsToDisplay[index].title : null} index={elemKey}
                        status={values.state}
                        name={"titles"} componentRender='textarea'
                        inputLabel="Title"
                        errors={errors ? errors.titles : null}
                        touched={touched ? touched.titles : null}
                        fieldsToDisplay={fieldsToDisplay}
                        setFieldsToDisplay={setFieldsToDisplay} fieldsToDisplayIndex={index}
                        labelClass={required ? 'required' : ''}/>
            <InputBlock
                status={values.state}
                value={fieldsToDisplay[index] ? fieldsToDisplay[index].description : null}
                index={elemKey}
                name={"descriptions"} componentRender='textarea'
                errors={errors ? errors.descriptions : null}
                touched={touched ? touched.descriptions : null}
                fieldsToDisplay={fieldsToDisplay}
                inputLabel="Description" setFieldsToDisplay={setFieldsToDisplay}
                fieldsToDisplayIndex={index}
                labelClass={required ? 'required' : ''}/>
            {
                required ?
                    <div></div> :
                    <div className={styles['remove-btn-wrapper']}>
                        <Button
                            disabled={disabled}
                            className='deleteLocaleBtn'
                            variant="outline-light"
                            onClick={removeLocale}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"
                                 fill="none">
                                <path
                                    d="M0.479266 0.477313C0.92558 0.0309997 1.6492 0.0309997 2.09551 0.477313L8.14453 6.52633L14.1936 0.477313C14.6399 0.0309997 15.3635 0.0309997 15.8098 0.477313C16.2561 0.923627 16.2561 1.64724 15.8098 2.09356L9.76077 8.14258L15.8098 14.1916C16.2561 14.6379 16.2561 15.3615 15.8098 15.8078C15.3635 16.2542 14.6399 16.2542 14.1936 15.8078L8.14453 9.75882L2.09551 15.8078C1.6492 16.2542 0.92558 16.2542 0.479266 15.8078C0.0329529 15.3615 0.0329529 14.6379 0.479266 14.1916L6.52829 8.14258L0.479266 2.09356C0.0329529 1.64724 0.0329529 0.923627 0.479266 0.477313Z"
                                    fill="#CED4DA"/>
                            </svg>
                        </Button>
                    </div>
            }
        </div>
    )
}
export default LocaleTemplate;