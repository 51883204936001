import React from "react";
import {Tooltip} from "react-bootstrap";
import './tooltip.css'

const renderTooltip = (props) => {
    return (
        <Tooltip className="tooltip-overlay"
                 id="button-tooltip"
                 {...props}>
            {props}
        </Tooltip>
    )
};

export default renderTooltip;