import React from 'react';
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";

const PricesBlock = ({disabled}) => {
    const currUrl = window.location.pathname.toString();
    const urlToSale = currUrl.replace(/all-inapps/, 'sales-inapps');

    return (
        <>
            <Button
                name={'isSales'}
                disabled={disabled}
                className="addRemoveSalesBtn"
                variant="outline-primary">
                <Link to={urlToSale} target="_blank" rel="noopener noreferrer"
                      className={disabled ? 'disabled-btn' : ''}>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.5 1C15.0523 1 15.5 1.44772 15.5 2V14C15.5 14.5523 15.0523 15 14.5 15H2.5C1.94772 15 1.5 14.5523 1.5 14V2C1.5 1.44772 1.94772 1 2.5 1H14.5ZM2.5 0C1.39543 0 0.5 0.895431 0.5 2V14C0.5 15.1046 1.39543 16 2.5 16H14.5C15.6046 16 16.5 15.1046 16.5 14V2C16.5 0.895431 15.6046 0 14.5 0H2.5Z"
                            fill="#0D6EFD"/>
                        <path
                            d="M8.5 4C8.77614 4 9 4.22386 9 4.5V7.5H12C12.2761 7.5 12.5 7.72386 12.5 8C12.5 8.27614 12.2761 8.5 12 8.5H9V11.5C9 11.7761 8.77614 12 8.5 12C8.22386 12 8 11.7761 8 11.5V8.5H5C4.72386 8.5 4.5 8.27614 4.5 8C4.5 7.72386 4.72386 7.5 5 7.5H8V4.5C8 4.22386 8.22386 4 8.5 4Z"
                            fill="#0D6EFD"/>
                    </svg>
                    Add sale
                </Link>
            </Button>
        </>
    );
};

export default PricesBlock;