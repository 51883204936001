import React, {useEffect, useState} from 'react';
import DatepickerDate from "./DatepickerDate";
import DatepickerTime from "./DatepickerTime";
import {useFormikContext} from "formik";

const DatePickerComponent = () => {
    const { values, errors, touched, setFieldValue } = useFormikContext();

    const [startDate, setStartDate] = useState(values.startTime ? values.startTime : null);
    const [startTime, setStartTime] = useState(values.startTime ?
        new Date().setUTCHours(new Date(values.startTime).getUTCHours(), new Date(values.startTime).getUTCMinutes())
        : new Date().setUTCHours(11, 0));
    const [endDate, setEndDate] = useState(values.endTime ? values.endTime : null);
    const [endTime, setEndTime] = useState(values.endTime ?
        new Date().setUTCHours(new Date(values.endTime).getUTCHours(), new Date(values.endTime).getUTCMinutes())
        : new Date().setUTCHours(11, 0));

    const changeDate = (value, fieldName) => {
        const date = new Date(fieldName === 'startTime' ? startTime : endTime);
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const newValue = value ? new Date(value).setUTCHours(hours, minutes) : null;
        if (fieldName === 'startTime') setStartDate(newValue);
        if (fieldName === 'endTime') setEndDate(newValue);
        setFieldValue(fieldName, newValue);

        // setTimeout( () => {
        //     setFieldTouched(fieldName, true, true);
        // }, 0 )
    }

    useEffect(() => {
        if (values.startTime){
            setStartDate(values.startTime);
            if (startTime) setStartTime(new Date().setUTCHours(new Date(values.startTime).getUTCHours(), new Date(values.startTime).getUTCMinutes()))
        }

        if (values.endTime){
            setEndDate(values.endTime);
            if (endTime) setEndTime(new Date().setUTCHours(new Date(values.endTime).getUTCHours(), new Date(values.endTime).getUTCMinutes()))
        }
    }, [values])

    const changeTime = (value, fieldName) => {
        let hours = new Date(value).getUTCHours();
        let minutes = new Date(value).getUTCMinutes();

        if (fieldName === 'startTime' && value) {
            setStartTime(value);
            if (startDate) {
                setFieldValue(fieldName, new Date(startDate).setUTCHours(hours, minutes));
                setFieldValue('startTimeHours', new Date(startDate).setUTCHours(new Date(startDate).getUTCHours(), new Date(startDate).getUTCMinutes()));
            }
        }
        else if (fieldName === 'endTime' && value) {
            setEndTime(value);
            if (endDate) {
                setFieldValue(fieldName, new Date(endDate).setUTCHours(hours, minutes));
                setFieldValue('endTimeHours', new Date(endDate).setUTCHours(new Date(endDate).getUTCHours(), new Date(endDate).getUTCMinutes()));
            }
        }
        else {
            if (fieldName === 'startTime') {
                setStartTime(null, null);
                setFieldValue('startTimeHours', null);
            }
            else {
                setEndTime(null, null);
                setFieldValue('endTimeHours', null);
            }
        }
    }

    return (
        <div className="datepicker-main-wrapper">
            <DatepickerDate
                status={values.state}
                label={'Start date'}
                selected={startDate}
                selects={'selectsStart'}
                startDate={startDate}
                endDate={endDate}
                onChange={(value) => changeDate(value, 'startTime')}
                errors={errors.startTime}
                touched={touched.startTime}
                errorName={'startTime'}
            />
            <DatepickerTime
                status={values.state}
                label={'Start time'}
                selected={startTime}
                onChange={(value) => changeTime(value, 'startTime')}
                errors={errors.startTimeHours}
                touched={touched.startTimeHours}
                errorName={'startTimeHours'}
                tooltipText='Default Start time: 11:00 UTC'
            />
            <DatepickerDate
                status={values.state}
                label={'End date'}
                selected={endDate}
                selects={'selectsEnd'}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                onChange={(value) => changeDate(value, 'endTime')}
                errors={errors.endTime}
                touched={touched.endTime}
                errorName={'endTime'}
            />
            <DatepickerTime
                status={values.state}
                label={'End time'}
                selected={endTime}
                onChange={(value) => changeTime(value, 'endTime')}
                errors={errors.endTimeHours}
                touched={touched.endTimeHours}
                errorName={'endTimeHours'}
                tooltipText='Default End time: 11:00 UTC'
            />
        </div>
    )
}

export default DatePickerComponent;