import React from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import {NavLink } from "react-router-dom";
import styles from './breadcrumbs.module.css'
import {
    GAMES_PATH,
    INAPP_EDITING_PATH,
    INAPPS_GROUPS_PATH, INAPPS_LIST_ALL_PATH, INAPPS_LIST_SALE_PATH,
    LOGIN_PATH,
    NEW_INAPP_PATH
} from "@constants/Paths";
import {v4} from "uuid";
import useGameName from "@hooks/useGameName";

const Breadcrumbs = () => {
    const gameId = window.location.pathname.match(/gameid-([^/]+)/)?.[1];
    const inappId = window.location.pathname.match(/inappid-([^/]+)/)?.[1];

    const gameName = useGameName();

    let routes = [
        {path: GAMES_PATH, breadcrumb: `Games`},
        {path: INAPPS_GROUPS_PATH, breadcrumb: `In-apps: ${gameId} (${gameName?.replace(/(\s*:.+)/, '')})`},
        {path: INAPPS_LIST_ALL_PATH, breadcrumb: `All in-apps`},
        {path: INAPPS_LIST_SALE_PATH, breadcrumb: `All sales`},
        {path: INAPP_EDITING_PATH, breadcrumb: `Editing in-app: ${inappId}`},
        {path: NEW_INAPP_PATH, breadcrumb: `New inapp`},
    ];
    let breadcrumbs = useBreadcrumbs(routes);

    //Библиотека по дефолту подтягивает path '/' под названием Home
    //Он нам не нужен
    breadcrumbs = breadcrumbs.filter(function (obj) {
        return obj.key !== '/';
    });

    if (breadcrumbs.length > 1 && breadcrumbs[0].key !== LOGIN_PATH) return (
        <div className={styles.breadcrumbsBlock}>
            {breadcrumbs.map(({
                                  match,
                                  breadcrumb
                              }, index) => (
                <React.Fragment key={v4()}>
                    {index === 0 ? '' : '/'}
                    <span key={match.pathname}>
                        {index === breadcrumbs.length - 1 ? <>{breadcrumb}</>
                            : <NavLink to={match.pathname}>{breadcrumb}</NavLink>
                        }
                </span>
                </React.Fragment>
            ))}
        </div>
    );
};

export default Breadcrumbs;