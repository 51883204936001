import {useState, useEffect} from "react";
import {
    Form,
    Button,
    Dropdown
} from 'react-bootstrap';
import styles from './searchControl.module.css'
import DropdownItem from "../DropdownComponent/DropdownItem";
import filteringCondition from "@utils/filteringMethods/filteringCondition";
import FilterGroup from "../FilterGroup/FilterGroup";

function SearchControl({searchingCondition, onSearch, setPage, data, titles, additionalTools, changeFilter}) {
    const [keyword, setKeyword] = useState('');
    const [filterType, setFilterType] = useState('');
    const [isShown, setIsShown] = useState(true);
    const [dropdownList, setDropdownList] = useState([]);
    const [filterList, setFilterList] = useState(data);
    const [options, setOptions] = useState([
        {
            value: '',
            label: 'All',
            amount: data ? data.filter(el => filteringCondition(el, '')).length : 0
        },
        {
            value: 'ready',
            label: 'Ready',
            amount: data ? data.filter(el => filteringCondition(el, 'ready')).length : 0
        },
        {
            value: 'onreview',
            label: 'On review',
            amount: data ? data.filter(el => filteringCondition(el, 'onreview')).length : 0
        },
        {
            value: 'active',
            label: 'Active',
            amount: data ? data.filter(el => filteringCondition(el, 'active')).length : 0
        }
    ]);

    const onHandleChange = (event) => {
        setKeyword(event.target.value);
        setIsShown(true);
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            const searchedData = data.filter(el => searchingCondition(el, keyword));
            const updatedOptions = options.map(option => ({
                ...option,
                amount: searchedData ? searchedData.filter(el => filteringCondition(el, option.value)).length : 0,
            }));

            if (data) onSearch(searchedData, true);
            setFilterList(searchedData);
            setOptions(updatedOptions);
            setIsShown(false);
        }
    }

    const resetChanges = (event) => {
        event.currentTarget.blur();

        const updatedOptions = options.map(option => ({
            ...option,
            amount: data ? data.filter(el => filteringCondition(el, option.value)).length : 0,
        }));

        setKeyword('');
        setFilterType('');
        setOptions(updatedOptions);
        setFilterList(data);
        if (data) onSearch(data, false);
    }

    useEffect(() => {
        changeFilter(filterType);
        if (filterList) onSearch(filterList.filter(el => filteringCondition(el, filterType)));
    }, [filterType, filterList])


    useEffect(() => {
        if (!data) return
        setDropdownList(data.filter(el => searchingCondition(el, keyword)).map((el, i) =>
            <DropdownItem key={i} title={el[titles]} keyword={keyword} to={el.xpromoId || el.id} slag={el.xpromoId ? 'gameid' : 'inappid'}/>
        ));
    }, [keyword]);

    useEffect(() => {
        const updatedOptions = options.map(option => ({
            ...option,
            amount: data ? data.filter(el => filteringCondition(el, option.value)).length : 0,
        }));

        setOptions(updatedOptions);
        setFilterList(data)
    }, [data]);

    return (
        <div className={styles.inputWrapper}>
            <div className={styles.inputGroup}>
                <div className={`${styles.suggestionBlock} ${styles[additionalTools]}`}>
                    <Form.Control
                        type="text"
                        className={styles.formControl}
                        name="keyword"
                        placeholder="Search by title, Id"
                        value={keyword}
                        onChange={onHandleChange}
                        onKeyDown={onKeyDown}
                    />

                    <Dropdown.Menu
                        show={keyword.length > 0 && dropdownList.length && isShown}
                        className={styles.dropdownMenu}>
                        {dropdownList}
                    </Dropdown.Menu>
                </div>
                {
                    {
                        'filter':
                            <>
                                <FilterGroup
                                    setPage={setPage}
                                    disable={!data}
                                    onClick={setFilterType.bind(this)}
                                    filterType={filterType}
                                    options={options}/>
                            </>
                    }[additionalTools]
                }
            </div>
            <Button
                variant="outline-primary"
                onClick={resetChanges}
            >Reset</Button>
        </div>
    );
}

export default SearchControl;
