import axios from "axios";
import {API_VERSION} from "@constants/API";
import {API_HOST} from "@constants/ENV";

export default function refreshToken(failedRequest) {
    let obj = {};
    obj.refreshToken = localStorage.getItem('refreshToken');

    return axios({
        method: 'post',
        url: `https://${API_HOST}/${API_VERSION}/RefreshAccessToken`,
        data: new URLSearchParams(obj)
    })
        .then(res => res.data)
        .then(function (response) {
            localStorage.setItem("accessToken", response.accessToken);

            const params = new URLSearchParams(failedRequest.config.data);
            const paramsObject = {};
            params.forEach((value, key) => {
                paramsObject[key] = value;
            });
            paramsObject.accessToken = response.accessToken;
            failedRequest.config.data = new URLSearchParams(paramsObject);

            return Promise.resolve();
        })
};