import React from 'react';
import {Card} from "react-bootstrap";
import styles from './inapps.module.css'
import Title from "@UIComponents/Title/Title";
import useGameName from "@hooks/useGameName";
import {Link, useLocation} from "react-router-dom";
import {GAME_QA_ROLE, MARKETING_QA_ROLE} from "@constants/ENV";

function InappsCategories() {
    const gameName = useGameName();
    const location = useLocation()

    return (
        <div className="mainContent">
            <Title mainText={gameName ? gameName.replace(/(\s*:\s*)/, '・') : ''}/>
            <div className={styles['card-wrapper']}>
                {localStorage.getItem('group') !== MARKETING_QA_ROLE && <Link to={location.pathname + '/all-inapps'}>
                    <Card className={styles['card-block']}>
                        <Card.Title className={styles['card-title']}>In-apps</Card.Title>
                    </Card>
                </Link>}
                {localStorage.getItem('group') !== GAME_QA_ROLE && <Link to={location.pathname + '/sales-inapps'}>
                    <Card className={styles['card-block']}>
                        <Card.Title className={styles['card-title']}>Sales</Card.Title>
                    </Card>
                </Link>}
            </div>
        </div>
    );
}

export default InappsCategories;