import {GET_CREDENTIALS} from "@actionTypes/userDataActionType";

const initialState = localStorage.getItem('accessToken');

const userDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CREDENTIALS:
            return action.payload;
        default:
            return state;
    }
};

export default userDataReducer;