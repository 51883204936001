import {GET_SALES} from "@actionTypes/salesActionTypes";
import axios from "axios";
import {API_VERSION} from "@constants/API";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import refreshToken from "@components/API/refreshToken";
import processPurchaseProducts from "@utils/filteringMethods/processPurchaseProducts";
import {API_HOST} from "@constants/ENV";
import axiosInherit from "axios-inherit";

const instance = axiosInherit(axios);
const getSales = async (dispatch, credential) => {

    const obj = {
        accessToken: credential
    }

    instance({
        method: 'post',
        url: `https://${API_HOST}/${API_VERSION}/GetPurchaseProductAlteredPrices`,
        data: new URLSearchParams(obj)
    })
        .then(res => res.data)
        .then(sales => {
            // sales were store here
            if (!sales.error) {
                dispatch({
                    type: GET_SALES, payload: processPurchaseProducts(sales.purchaseProductAlteredPrices, sales.purchaseProductAlteredPriceDrafts)
                })
            }
        })
        .catch(reqErr => console.error(reqErr))
};

createAuthRefreshInterceptor(instance, refreshToken, {
    statusCodes: [403]
});

export default getSales;