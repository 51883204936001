import React from 'react';
import Button from "react-bootstrap/Button";
import {Link, useLocation} from "react-router-dom";

const AddNewInappBtn = ({styles, label = 'Add new in-app', pathPart = '/new-inapp', disabled}) => {
    const location = useLocation()
    return (
        <Link to={`${location.pathname + pathPart}`} target="_blank">
            <Button
                disabled={disabled}
                className={`additional-styles-${styles} add-new-inapp-btn`}
                variant={styles}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM8.5 4.5V7.5H11.5C11.7761 7.5 12 7.72386 12 8C12 8.27614 11.7761 8.5 11.5 8.5H8.5V11.5C8.5 11.7761 8.27614 12 8 12C7.72386 12 7.5 11.7761 7.5 11.5V8.5H4.5C4.22386 8.5 4 8.27614 4 8C4 7.72386 4.22386 7.5 4.5 7.5H7.5V4.5C7.5 4.22386 7.72386 4 8 4C8.27614 4 8.5 4.22386 8.5 4.5Z"
                        fill={styles === 'primary' ? 'white' : 'rgb(13, 110, 253)'}/>
                </svg>
                {label}
            </Button>
        </Link>
    );
};

export default AddNewInappBtn;