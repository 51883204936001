import {GET_INAPPS} from "@actionTypes/inappsActionTypes";
import axios from "axios";
import {API_VERSION} from "@constants/API";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import refreshToken from "@components/API/refreshToken";
import processPurchaseProducts from "@utils/filteringMethods/processPurchaseProducts";
import {API_HOST} from "@constants/ENV";
import axiosInherit from "axios-inherit";

const instance = axiosInherit(axios);
const getInapps = async (dispatch, credential, xpromoId) => {
    dispatch({
        type: GET_INAPPS,
        payload: null
    });

    const obj = {
        accessToken: localStorage.getItem("accessToken")
    }

    instance({
        method: 'post',
        url: `https://${API_HOST}/${API_VERSION}/GetSkuInfo?xpromoId=${xpromoId}`,
        data: new URLSearchParams(obj)
    })
        .then(res => res.data)
        .then(inapps => {
            // inapps were store here
            if (!inapps.error) {
                dispatch({
                    type: GET_INAPPS, payload: processPurchaseProducts(inapps.purchaseProducts, inapps.purchaseProductDrafts)
                })
            }
        })
        .catch(reqErr => console.error(reqErr))
};

createAuthRefreshInterceptor(instance, refreshToken, {
    statusCodes: [403]
});

export default getInapps;