import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {ToastContainer} from "react-bootstrap";
import ToastMessege from "../ToastMessege/ToastError";
import ToastSuccess from "../ToastMessege/ToastSuccess";

const Notification = () => {
    const location = useLocation();
    const [showToast, setShowToast] = useState(false);
    const [toastText, setToastText] = useState('Successfully updated');
    const [errorDetails, setErrorDetails] = useState({});

    axios.interceptors.response.use(response => {
        return response;
    }, async (error) => {
        console.log("ERROR", error.response);

        if (error.response.data.error !== "AccessTokenExpired" && error.response.data.error !== "RefreshTokenExpired") {
            setErrorDetails(error);
        }

        switch (error.response.status) {
            case 401 :
                localStorage.clear();
                window.location = `https://${window.location.hostname}/login`;
                window.dispatchEvent(new Event('storage'));
                break;
            case 403 :
                if (error.response.data.error === "RefreshTokenExpired") {
                    localStorage.clear();
                    window.location = `https://${window.location.hostname}/login`;
                    window.dispatchEvent(new Event('storage'));
                }
                break;
            default :
                break;
        }
        // Do something with response error
        return Promise.reject(error);
    })

    useEffect(() => {
        if (location.state) {
            setShowToast(!!location.state.updateCompleted);
            setToastText(location.state.message)
        };
        //Чищу location.state
        window.history.replaceState({}, document.title);
    }, [location.state])

    return (
        <ToastContainer className="fixed-toast" position='top-end'>
            {errorDetails && <ToastMessege error={errorDetails}/>}
            <ToastSuccess status={showToast} text={toastText}/>
        </ToastContainer>
    );
};

export default Notification;