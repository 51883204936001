import React, {useState, useEffect, useRef, useContext} from 'react';
import NavigationBar from '@inappPageComponents/NavigationBar/NavigationBar.js'
import DropdownComponent from '@components/UI/DropdownComponent/DropdownComponent.js'
import ScrollToTopBtn from "@UIComponents/ScrollToTop/ScrollToTopBtn";
import '../../styles/index.css'
import {Formik, Form} from "formik";
import useSale from "@hooks/useSale";
import {useParams} from "react-router-dom";
import Title from "@UIComponents/Title/Title";
import {useNavigate} from 'react-router-dom';
import useInappsId from "@hooks/useInappsId";
import SaleValidation from "@utils/validations/SaleValidation";
import styles from '../inapps.module.css'
import {SALE_NAVIGATION} from "@constants/Navigation";
import FormObserver from "@helpers/FormObserver";
import createSelectList from "./methods/createSelectList";
import submitData from "./methods/submitData";
import UseGameName from "@hooks/useGameName";
import SaleInappId from "@inappPageComponents/InappId/SaleInappId";
import SalesComponent from "@inappPageComponents/Sales/SalesComponent";
import SaleActionButtons from "@inappPageComponents/ActionButtons/SaleActionButtons";
import {AppContext} from "../../../App";
import SaleRollbackButtons from "@inappPageComponents/ActionButtons/SaleRollbackButtons";
import {displayProductionStatus} from "@utils/UIMethods/displayProductionStatus";
import useBeforeUnload from "@hooks/useBeforeUnload";
import {useSkipInappValidation} from "@hooks/useSkipInappValidation";

const SaleInapp = () => {
    const [selectedInappId, setSelectedInappId] = useState('');

    const ref = useRef(null);
    const {inappId} = useParams();
    const {gameId} = useParams();
    const oneInapp = useSale(gameId, inappId, selectedInappId);
    const navigate = useNavigate();
    const inappsIdArray = useInappsId(gameId);
    const gameName = UseGameName();
    const skipValidation = useSkipInappValidation();

    const {tiers} = useContext(AppContext);
    const [price, setPrice] = useState();
    const [options, setOptions] = useState(createSelectList(tiers, inappId));
    const [valuesChanged, setValuesChanged] = useState(false);

    const validation = SaleValidation(inappId ? [null] : inappsIdArray);

    useBeforeUnload();

    const requestProcessing = (status, setSubmitting, lastMethodProcessed, message) => {
        if (lastMethodProcessed) {
            if (status === 200) navigate(`/games/gameid-${gameId}/sales-inapps`, {
                state: {
                    updateCompleted: true,
                    message
                }
            });
            setSubmitting(false)
        }
    }

    useEffect(() => {
        setOptions(createSelectList(tiers, inappId));
    }, [tiers]);

    return (
        <div className="mainContent">
            <div className="title-status-block title-wrapper">
                <Title mainText={`${inappId && oneInapp.state ? "Editing sale:" : "New sale:"}`}
                       secondaryText={gameName}/>
                {
                    inappId && oneInapp.state &&
                    <>
                        <div
                            className={`status-wrapper ${oneInapp.state.toLowerCase()
                                .replace(' ', '')}`}>{oneInapp.state}</div>
                        {displayProductionStatus(oneInapp.state, oneInapp.hasActivePurchase)}
                    </>
                }
            </div>
            <hr className="horizontal-line"/>
            <Formik
                innerRef={ref}
                enableReinitialize={!!inappId}
                initialValues={oneInapp}
                validationSchema={validation}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, {setSubmitting}) => {
                    submitData({...values, gameId, skipValidation}, 'submit', setSubmitting, requestProcessing);
                }}
            >
                {
                    ({
                         setFieldValue
                     }) => {
                        return (
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                setTimeout(() => {
                                    document.querySelector(".is-invalid")?.scrollIntoView({
                                        behavior: "smooth",
                                        block: "start"
                                    });
                                }, 100)
                            }}>
                                <FormObserver defaultValues={oneInapp}
                                              setValuesChanged={setValuesChanged}/>
                                <div className="innap-body">
                                    <div className="newinapp-left-block">
                                        <div className="newinapp-section">
                                            <div id="xpromo"></div>
                                            <div className={styles['sale-info-wrapper']}>
                                                <SaleInappId index={'id'}
                                                             newTier={oneInapp.priceTier}
                                                             selectedInappId={selectedInappId}
                                                             setSelectedInappId={setSelectedInappId}/>
                                                <DropdownComponent
                                                    category={'sale'}
                                                    options={options}
                                                    label={'priceTier'}
                                                    labelClass={'form-title'}
                                                    price={price}
                                                    setPrice={setPrice}
                                                    additionalSign="$"
                                                    onChange={(option) => setFieldValue('priceTier', option.id)}
                                                />
                                            </div>
                                        </div>
                                        <div className="newinapp-section">
                                            <div id="prices"></div>
                                            <SalesComponent
                                                status={oneInapp.state}
                                                price={price}
                                                options={options}/>
                                        </div>
                                    </div>

                                    <div className="newinapp-right-block">
                                        <NavigationBar
                                            navigation={SALE_NAVIGATION}
                                        />
                                    </div>

                                </div>
                                <div className="action-block">
                                    <div className='inner-action-block'>
                                        <SaleActionButtons
                                            category={'sale'}
                                            inappId={inappId}
                                            valuesChanged={valuesChanged}
                                        />
                                    </div>
                                    <div>
                                        <SaleRollbackButtons/>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
            </Formik>
            <ScrollToTopBtn/>
        </div>
    );
};

export default SaleInapp;
