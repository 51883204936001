import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import styles from "./ModalComponent.module.css";
import { useFormikContext } from 'formik';

const ModalRollback = ({methodName, submitData, modalText, btnText, confirmBtnText, description, variant="outline-success"}) => {
    const { submitForm, values, isSubmitting } = useFormikContext();
    const [modalState, setModalState] = useState("close");
    const handleClose = (event) => {
        setModalState("close");
        event.currentTarget.blur();
    }
    return (
        <>
            <Button
                disabled={isSubmitting}
                variant={variant}
                onClick={(event) => {
                    setModalState("show");
                    event.currentTarget.blur();
                }}>
                {btnText}
            </Button>
            <Modal
                centered
                onHide={handleClose}
                show={modalState === "show"}>
                <Modal.Header closeButton>
                    <Modal.Title className={styles.modalTitle}>
                        {modalText}
                    </Modal.Title>
                </Modal.Header>
                {description && <Modal.Body>
                    <p>{description}</p>
                </Modal.Body>}
                <Modal.Footer className={styles.modalFooterFlexEnd}>
                    <Button
                        variant="outline-secondary"
                        onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant={variant.replace('outline-', '')}
                        type='submit'
                        className={styles.modalBtn}
                        onClick={(event) => {
                            submitData(values, methodName);
                            submitForm();
                            setModalState("close");
                            event.currentTarget.blur();
                        }}>{confirmBtnText}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalRollback;