import React from 'react';
import ModalRollback from "@UIComponents/Modal/ModalRollback";
import {useFormikContext} from "formik";
import submitData from "@inappPages/InappsPage/methods/submitData";
import {CONTENT_EDITOR_ROLE, MARKETING_QA_ROLE} from "@constants/ENV";

const SaleRollbackButtons = () => {
    const group = localStorage.getItem('group');
    const {values} = useFormikContext();

    if (values.state === 'Ready' && group === MARKETING_QA_ROLE) {
        return <ModalRollback
            methodName={'ReturnPurchaseProductAlteredPriceDraftToOnReview'}
            submitData={submitData}
            modalText={'Are you sure you want to Roll back to “On review”?'}
            btnText={'Roll back to “On review”'}
            confirmBtnText={'Yes, roll back'}
            variant="outline-primary"
        />
    } else if (values.state === 'OnReview' && group === CONTENT_EDITOR_ROLE && !values.hasActivePurchase) {
        return <ModalRollback
            variant={'outline-danger'}
            methodName={'DeletePurchaseProductAlteredPriceDraft'}
            submitData={submitData}
            modalText={'Are you sure you want to remove sale?'}
            description={'Confirming "Yes, remove" will permanently delete all data about this sale. This action is irreversible.'}
            btnText={'Remove sale'}
            confirmBtnText={'Yes, remove'}
        />
    } else if (values.state === 'OnReview' && group === CONTENT_EDITOR_ROLE && values.hasActivePurchase) {
        return <div className="inner-action-block">
            <ModalRollback
            methodName={'RollbackPurchaseProductAlteredPriceDraft'}
            submitData={submitData}
            modalText={'Are you sure you want to Roll back to “Active”?'}
            description={'Confirming "Yes, roll back" will remove all changes you made previously and the sale will return to Active status.'}
            btnText={'Roll back to “Active”'}
            confirmBtnText={'Yes, roll back'}
            variant="outline-success"
        />
            <ModalRollback
                variant={'outline-danger'}
                methodName={['DeletePurchaseProductAlteredPriceDraft', 'DeletePurchaseProductAlteredPrice']}
                submitData={submitData}
                modalText={'Are you sure you want to remove sale?'}
                description={'Confirming "Yes, remove" will permanently delete all data about this sale. This action is irreversible.'}
                btnText={'Remove sale'}
                confirmBtnText={'Yes, remove'}
            /></div>
    } else if (values.state === 'Active' && group === CONTENT_EDITOR_ROLE) {
        return <ModalRollback
            variant={'outline-danger'}
            methodName={'DeletePurchaseProductAlteredPrice'}
            submitData={submitData}
            modalText={'Are you sure you want to remove sale?'}
            description={'Confirming "Yes, remove" will permanently delete all data about this sale. This action is irreversible.'}
            btnText={'Remove sale'}
            confirmBtnText={'Yes, remove'}
        />
    }
};

export default SaleRollbackButtons;