import React, {useState} from 'react';
import styles from './ScrollToTopBtn.module.css'

const topFunction = () => {
    document.body.scrollTop = 0; // Для Safari
    document.documentElement.scrollTop = 0; // Для Chrome, Firefox, IE и Opera
}

const ScrollToTopBtn = () => {
    const [btnOpacity, setBtnOpacity] = useState(0);
    window.onscroll = () => {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            setBtnOpacity(1)
        } else {
            setBtnOpacity(0)
        }
    }

    return (
        <button
            style={{'opacity': btnOpacity}}
            onClick={topFunction}
            className={styles.scrollButton}>
            <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.6364 0.863604C11.2849 0.512132 10.7151 0.512132 10.3636 0.863604L1.3636 9.86361C1.01213 10.2151 1.01213 10.7849 1.3636 11.1364C1.71508 11.4879 2.28492 11.4879 2.6364 11.1364L11 2.77279L19.3636 11.1364C19.7151 11.4879 20.2849 11.4879 20.6364 11.1364C20.9879 10.7849 20.9879 10.2151 20.6364 9.86361L11.6364 0.863604Z"
                    fill="#212529" stroke="black" strokeWidth="0.3" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </button>
    );
};

export default ScrollToTopBtn;