import createPurchase from "@reduxServices/createPurchase";
import createPurchaseProductDraft from "@reduxServices/updateActivePurchase";
import updatePurchase from "@reduxServices/updatePurchase";
import ApprovePurchaseProductDraft from "@reduxServices/approvePurchase";
import returnPurchase from "@reduxServices/returnPurchase";
import deletePurchase from "@reduxServices/deletePurchase";
import CreatePurchaseProductAlteredPriceDraft from "@reduxServices/createPurchaseProductAlteredPriceDraft";
import DeletePurchaseProductAlteredPriceDraft from "@reduxServices/deletePurchaseProductAlteredPriceDraft";
import UpdatePurchaseProductAlteredPriceDraft from "@reduxServices/updatePurchaseProductAlteredPriceDraft";
import ApprovePurchaseProductAlteredPriceDraft
    from "@reduxServices/approvePurchaseProductAlteredPriceDraft";
import ReturnPurchaseProductAlteredPriceDraftToOnReview
    from "@reduxServices/returnPurchaseProductAlteredPriceDraftToOnReview";
import DeletePurchaseProductAlteredPrice from "@reduxServices/deletePurchaseProductAlteredPrice";

let method;
const submitData = (data, param, setSubmitting, requestProcessing) => {
    if (param !== 'submit') method = Array.isArray(param) ? param : [param];
    else {
        const availableMethods = {
            createPurchase,
            createPurchaseProductDraft,
            updatePurchase,
            updatePurchaseStatus: ApprovePurchaseProductDraft,
            rollbackPurchase: returnPurchase,
            deletePurchase,
            CreatePurchaseProductAlteredPriceDraft,
            CreatePurchaseProductAlteredPriceDraftFromActive: CreatePurchaseProductAlteredPriceDraft,
            RollbackPurchaseProductAlteredPriceDraft: DeletePurchaseProductAlteredPriceDraft,
            DeletePurchaseProductAlteredPriceDraft,
            DeletePurchaseProductAlteredPrice,
            UpdatePurchaseProductAlteredPriceDraft,
            ApprovePurchaseProductAlteredPriceDraft,
            ReturnPurchaseProductAlteredPriceDraftToOnReview,
        };
        const successMessages = {
            createPurchase: 'In-app was successfully created',
            createPurchaseProductDraft: 'In-app was successfully updated',
            updatePurchase: 'In-app was successfully updated',
            updatePurchaseStatus: 'In-app was successfully approved',
            rollbackPurchase: 'In-app was successfully rolled back',
            deletePurchase: 'In-app was successfully rolled back to "Active"!',
            CreatePurchaseProductAlteredPriceDraft: 'Sale was successfully created',
            CreatePurchaseProductAlteredPriceDraftFromActive: 'Sale was successfully updated',
            DeletePurchaseProductAlteredPriceDraft: 'Sale successfully removed!',
            RollbackPurchaseProductAlteredPriceDraft: 'Sale was successfully rolled back to "Active"!',
            DeletePurchaseProductAlteredPrice: "Sale was successfully removed!",
            UpdatePurchaseProductAlteredPriceDraft: "Sale was successfully updated",
            ApprovePurchaseProductAlteredPriceDraft: "Sale was successfully approved",
            ReturnPurchaseProductAlteredPriceDraftToOnReview: "Sale was successfully rolled back"
        };

        method.forEach((m, i) => {
            if (m && availableMethods[m]) {
                const selectedMethod = availableMethods[m];
                selectedMethod(data)
                    .then(resp => requestProcessing(resp, setSubmitting, i === method.length - 1, successMessages[m] || "Successfully updated"),
                            error => requestProcessing(error, setSubmitting));
            }
        })
    }
};

export default submitData;