import {GET_CREDENTIALS} from "@actionTypes/userDataActionType";
import jwt_decode from "jwt-decode";
import {GAMES_PATH} from "@constants/Paths";

export const getCredential = async (code, navigate, dispatch) => {
    localStorage.setItem('accessToken', code.accessToken);
    localStorage.setItem('refreshToken', code.refreshToken);
    localStorage.setItem('userName', jwt_decode(code.accessToken).name);
    localStorage.setItem('group', code.authorizedGroupName);
    window.dispatchEvent( new Event('storage') )
    dispatch({type: GET_CREDENTIALS, payload: code.accessToken})
    navigate(GAMES_PATH)
}

