import React from 'react';

const ErrorPage = () => {
    return (
        <div className="error-page-wrapper">
            <div className="error-code">404</div>
            <div className="error-text">This page does not exist anymore :(</div>
        </div>
    );
};

export default ErrorPage;