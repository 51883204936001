import React, {useEffect, useState} from 'react';
import styles from './Loading.module.css'
import {Spinner} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {getCredential} from "@actionCreators/userDataActionCreator";
import {useDispatch} from "react-redux";

const Loading = ({background, text}) => {
    const navigate = useNavigate();
    const [code, setCode] = useState();
    const dispatch = useDispatch();
    const additionalClass = background === 'transparent' ? styles.loadingWrapperTransparent : styles.loadingWrapperGray

    useEffect(() => {
        const url = decodeURIComponent(window.location.href);
        const regex = /userAuthInfo=([^&]+)/;
        const match = url.match(regex);

        if (match && match.length > 1) {
            setCode(JSON.parse(match[1]))
        }
    }, [])

    useEffect(() => {
        if(code) getCredential(code, navigate, dispatch)
    }, [code]);

    return (
        <div className={`${styles.loadingWrapper}  ${additionalClass}`}>
            <div className={styles.loadingText}>{text}</div>
            <Spinner className={styles.spinnerWrapper} animation="border" variant="primary"/>
        </div>
    );
};

export default Loading;