import React, {useContext} from 'react';
import {AppContext} from "../App";

export default function useInappsId() {
    const {inapps} = useContext(AppContext);
    let inappsIdArray = [];

    inapps?.map((item) => {
        inappsIdArray.push(item.id)
    })

    return inappsIdArray;
};