import {GET_GAMES} from "@actionTypes/gamesActionType";
import axios from "axios";
import {API_VERSION} from "@constants/API";
import {API_HOST} from "@constants/ENV";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import refreshToken from "@components/API/refreshToken";
import axiosInherit from "axios-inherit";

const instance = axiosInherit(axios);
const getGames = async (dispatch, credential, locale = 'en') => {
    //плучаю JWT
    const obj = {
        accessToken: credential
    }

    instance({
        method: 'post',
        url: `https://${API_HOST}/${API_VERSION}/GetSkus`,
        data: new URLSearchParams(obj)
    })
        .then(res => res.data)
        .then(games => {
            // games were store here
            if (!games.error) {
                games.sort((a, b) => a.names[locale].toLowerCase().localeCompare(b.names[locale].toLowerCase()));

                dispatch({type: GET_GAMES, payload: games.map((g) => ({xpromoId: g.xpromoId, name: g.names[locale], skipValidation: !!g.skipValidation}))})
            }
        })
        .catch(reqErr => console.error(reqErr))
};

createAuthRefreshInterceptor(instance, refreshToken, {
    statusCodes: [403]
});

export default getGames;