import React from "react";
import {Link} from "react-router-dom";
import {OverlayTrigger} from "react-bootstrap";
import renderTooltip from "../Tooltip/Tooltip";
import styles from './table.module.css'
import {displayProductionStatus} from "@utils/UIMethods/displayProductionStatus";

const TCell = ({element}) => {
    const renderSwitch = (k, i) => {
        switch (k) {
            case 'state':
                return <td key={i}>
                    <div
                        className={`status-wrapper ${element[k].toLowerCase().replace(' ', '')}`}>{element[k]}</div>
                </td>;
            case 'xpromoId':
                return <td key={i}>
                    <Link
                        className="gamesLink"
                        to={`/games/gameid-${element[k]}`}
                        state={{
                            gameName: element.name
                        }}
                    >
                        {element[k]}
                    </Link>
                </td>;
            case 'price':
                return <td key={i}>
                    ${element[k]}
                </td>;
            case 'id':
                return <td key={i}>
                    <Link
                        className="gamesLink"
                        to={window.location.pathname + `/inappid-${element.id}`}
                        state={{
                            inappId: element[k],
                            inappStatus: element.state
                        }}
                    >
                        {element[k]}
                    </Link>
                </td>
            case 'priceTier':
                return <td key={i}>
                    Tier {element[k]}
                </td>
            case 'dates':
                return <td key={i}>
                    <div className="datesBlock">
                        {element[k].date}
                        {element[k].warningSign ?
                            <OverlayTrigger
                                placement="top"
                                delay={{show: 250, hide: 400}}
                                overlay={renderTooltip('Sale is overdue')}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
                                    <path
                                        d="M10.7088 3.74999L2.45878 18C2.32735 18.2276 2.25805 18.4858 2.25781 18.7487C2.25758 19.0115 2.32643 19.2698 2.45745 19.4977C2.58848 19.7256 2.77709 19.915 3.00438 20.0471C3.23168 20.1791 3.48967 20.2491 3.75253 20.25H20.2525C20.5154 20.2491 20.7734 20.1791 21.0007 20.0471C21.228 19.915 21.4166 19.7256 21.5476 19.4977C21.6786 19.2698 21.7475 19.0115 21.7472 18.7487C21.747 18.4858 21.6777 18.2276 21.5463 18L13.2963 3.74999C13.1658 3.52206 12.9775 3.33263 12.7503 3.20088C12.5231 3.06914 12.2652 2.99976 12.0025 2.99976C11.7399 2.99976 11.4819 3.06914 11.2548 3.20088C11.0276 3.33263 10.8392 3.52206 10.7088 3.74999Z"
                                        fill="#FFC107" stroke="#FFC107" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path
                                        d="M12.375 15C11.7321 15 11.25 15.4821 11.25 16.125C11.25 16.7679 11.7321 17.25 12.375 17.25C13.0179 17.25 13.5 16.7679 13.5 16.125C13.5 15.4821 13.0179 15 12.375 15Z"
                                        fill="#212529"/>
                                    <path
                                        d="M12.9136 13.875L13.3421 9.375C13.4493 8.78571 13.0207 8.25 12.3778 8.25C11.735 8.25 11.3064 8.73214 11.36 9.375L11.7886 13.875H12.9136Z"
                                        fill="#212529"/>
                                </svg>
                            </OverlayTrigger> : ''}
                    </div>
                </td>
            case 'hasActivePurchase':
                return <td key={i}>{displayProductionStatus(element.state, element.hasActivePurchase)}</td>
            default:
                return <td key={i}><div className={styles["hide-long-text"]}>{element[k]}</div></td>;
        }
    }

    return (
        <tr>
            {
                Object.keys(element).map((k, i) =>
                    renderSwitch(k, i)
                )
            }
        </tr>
    );
};

export default TCell;