import React, {useContext, useEffect} from 'react'
import Select from 'react-select'
import './DropdownComponent.css'
import {ErrorMessage, useFormikContext} from "formik";
import useInappStatus from "@hooks/useInappStatus";
import {useParams} from "react-router-dom";
import {AppContext} from "../../../App";
import {CONTENT_EDITOR_ROLE} from "@constants/ENV";

const DropdownComponent = ({
                               additionalSign, onChange, options, price, setPrice, labelClass,
                               label, title = 'Tier Price, USD', category
                           }) => {
    const {values, errors, touched} = useFormikContext();
    const {tiers} = useContext(AppContext);
    const {gameId} = useParams();

    const validationError = !!(errors[label] && errors[label].length);
    const status = useInappStatus();
    const disabled = status === "Ready" || localStorage.getItem('group') !== CONTENT_EDITOR_ROLE || category === 'sale';

    useEffect(() => {
        if (tiers.length && values[label]) setPrice(getCurrentPriceTier(values[label], tiers, gameId));
    }, [values[label], tiers])

    return (
        <div className='dropdown-wrapper'>
            <div className={`${labelClass}`}>{title}</div>
            <div className={`selectBlock ${
                touched[label] && validationError ? "is-invalid" : ""}`}>
                <Select
                    onChange={(e) => onChange(e)}
                    className={`${additionalSign ? 'prices-block customSelect' : 'inapps-list customSelect'} ${disabled ? 'non-editable-mode' : ''}`}
                    classNamePrefix="react-select"
                    options={options}
                    placeholder={'—'}
                    value={price}
                    name={label}
                    styles={{
                        dropdownIndicator: (provided, state) => ({
                            ...provided,
                            transform: state.selectProps.menuIsOpen && "rotate(180deg)"
                        })
                    }}
                />
                {
                    additionalSign &&
                    <div className={'labelBlock'}>
                        <span>{additionalSign}</span>
                    </div>
                }
            </div>
            <ErrorMessage
                component="div"
                name={label}
                className="invalid-feedback"
            />
        </div>
    )
}

function getCurrentPriceTier(priceTearId, tiers, id) {
    let price = {};
    let platform = id.match(/com\.g5e\.[^\.]+\.([^\.]+)/)?.[1];

    tiers.forEach(item => {
        if (item.tier.toString() === priceTearId.toString()) {
            price = {
                prices: {...(item[platform] || item.web)},
                label: `${item.tier} - ${item[platform]?.USD || item.web.USD}`
            };
        }
    })

    return Object.keys(price).length ? price : ' - ';
}

export default DropdownComponent;
