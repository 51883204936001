let API_HOST, CONTENT_EDITOR_ROLE, GAME_QA_ROLE, MARKETING_QA_ROLE;
const API_HOST_PROD = 'api.store.g5e.com';
const API_HOST_TEST = 'api.test.store.g5e.com';
const API_HOST_DEV = 'api.dev-store.g5.com';

const ADMIN_HOST_PROD = 'admin.store.g5e.com';
const ADMIN_HOST_TEST = 'admin.test.store.g5e.com';
const ADMIN_HOST_DEV = 'admin.dev-store.g5.com';
const ADMIN_HOST_DEV_LOCAL = 'localhost';

const ADMIN_HOST_CURRENT = window.location.hostname;

const CONTENT_EDITOR_ROLE_TEST = 'test-store-g5-content-editor';
const GAME_QA_ROLE_TEST = 'test-store-g5-game-qa';
const MARKETING_QA_ROLE_TEST = 'test-store-g5-mrkt-qa';

switch (ADMIN_HOST_CURRENT) {
    case ADMIN_HOST_PROD: {
        // API
        API_HOST = API_HOST_PROD;
        // Roles
        CONTENT_EDITOR_ROLE = 'store-g5-content-editor';
        GAME_QA_ROLE = 'store-g5-game-qa';
        MARKETING_QA_ROLE = 'store-g5-mrkt-qa';
        break;
    }
    case ADMIN_HOST_TEST: {
        // API
        API_HOST = API_HOST_TEST;
        // Roles
        CONTENT_EDITOR_ROLE = CONTENT_EDITOR_ROLE_TEST
        GAME_QA_ROLE = GAME_QA_ROLE_TEST;
        MARKETING_QA_ROLE = MARKETING_QA_ROLE_TEST;
        break;
    }
    case ADMIN_HOST_DEV: {
        // API
        API_HOST = API_HOST_DEV;
        // Roles
        CONTENT_EDITOR_ROLE = CONTENT_EDITOR_ROLE_TEST
        GAME_QA_ROLE = GAME_QA_ROLE_TEST;
        MARKETING_QA_ROLE = MARKETING_QA_ROLE_TEST;
        break;
    }
    case ADMIN_HOST_DEV_LOCAL: {
        // API
        API_HOST = API_HOST_TEST;
        // Roles
        CONTENT_EDITOR_ROLE = CONTENT_EDITOR_ROLE_TEST
        GAME_QA_ROLE = GAME_QA_ROLE_TEST;
        MARKETING_QA_ROLE = MARKETING_QA_ROLE_TEST;
        break;
    }
    default: {
        API_HOST = 'unknown_host_url';
        break;
    }
}

export {API_HOST, CONTENT_EDITOR_ROLE, GAME_QA_ROLE, MARKETING_QA_ROLE};
