function createSelectList(options, id) {
    const platform = id?.match(/com\.g5e\.[^\.]+\.([^\.]+)/)?.[1];
    const arr = options?.map(item => ({
        id: item.tier,
        value: item[platform]?.USD || item.web.USD,
        label: `${item.tier} - ${item[platform]?.USD || item.web.USD}`
    }));
    return arr.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
}

export default createSelectList;