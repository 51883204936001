import React, {useState, useEffect} from "react";
import {ButtonGroup, Button} from "react-bootstrap";
import styles from './pagination.module.css'

const Pagination = ({page, elementsAmount, handleChange, elementsPerPage = 10, setPage}) => {
    const [totalPagesAmount, setTotalPagesAmount] = useState(1);

    const url = new URL(window.location);

    useEffect(() => {
        setTotalPagesAmount(Math.ceil(elementsAmount / elementsPerPage))
        if (+page > Math.ceil(elementsAmount / elementsPerPage)) {
            setPage(Math.ceil(elementsAmount / elementsPerPage));
            url.searchParams.set('page', Math.ceil(elementsAmount / elementsPerPage));
            window.history.pushState({}, '', url);
        }
    }, [elementsAmount]);

    return (
        <>
            {totalPagesAmount ? (
                <ButtonGroup>
                    <Button
                        disabled={+page < 2}
                        onClick={(event) => {
                            handleChange(+page - 1);
                            event.currentTarget.blur();
                        }}
                        variant="outline-primary"
                        className={styles.paginationButtons}
                    >
                        Previous
                    </Button>
                    {[...Array(totalPagesAmount)].map((p, i) => (
                        <Button
                            key={i}
                            variant="outline-primary"
                            className={`${styles.paginationButtons} ${
                                i + 1 === +page ? 'active' : ''
                            }`}
                            onClick={(event) => {
                                handleChange(i + 1);
                                event.currentTarget.blur();
                            }}
                        >
                            {i + 1}
                        </Button>
                    ))}
                    <Button
                        disabled={+page === totalPagesAmount}
                        onClick={(event) => {
                            handleChange(+page + 1);
                            event.currentTarget.blur();
                        }}
                        variant="outline-primary"
                        className={styles.paginationButtons}
                    >
                        Next
                    </Button>
                </ButtonGroup>
            ) : (
                <ButtonGroup>
                    <Button
                        variant="outline-primary"
                        className={`active ${styles.paginationButtons}`}
                    >
                        1
                    </Button>
                </ButtonGroup>
            )}
        </>
    );
};

export default Pagination;
