import React, {useState} from 'react';
import "react-datepicker/dist/react-datepicker.css"
import './Sales.css'
import DatePickerComponent from "@UIComponents/Datepicker/DatepickerComponent";
import SalesInputPrices from "./SalesInputPrices";
import DropdownComponent from "@UIComponents/DropdownComponent/DropdownComponent";
import {useFormikContext} from "formik";
import {Button} from "react-bootstrap";
import {CONTENT_EDITOR_ROLE} from "@constants/ENV";


const SalesComponent = ({price, options, status}) => {
    const [salePrice, setSalePrice] = useState();
    const {setFieldValue} = useFormikContext();

    const fillSalePrices = (event) => {
        if (salePrice) Object.keys(salePrice.prices).forEach((key) => {
            setFieldValue('salePrices.' + key, salePrice.prices[key])
        })
        event.currentTarget.blur();
    }

    return (
        <>
            <h2 className="form-title subtitle">Sale Date</h2>
            <DatePickerComponent/>
            <div className="sale-helper-wrapper">
            <h2 className="form-title subtitle">Sale Price</h2>
                <div className="sale-helper-block">
                    <DropdownComponent
                        title={''}
                        options={options}
                        label={'salePriceTier'}
                        labelClass={'form-title'}
                        price={salePrice}
                        setPrice={setSalePrice}
                        additionalSign="$"
                        onChange={(option) => setFieldValue('salePriceTier', option.id)}
                    />
                    <Button
                        variant="outline-primary"
                        disabled={status === "Ready" || localStorage.getItem('group') !== CONTENT_EDITOR_ROLE}
                        onClick={(event) => fillSalePrices(event)}>
                        Autofill sale prices
                    </Button>
                </div>
                <div className="sale-instructions">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.9307 6.58789L7.92875 11.293C7.85844 11.6328 7.95805 11.8262 8.23344 11.8262C8.4268 11.8262 8.71977 11.7559 8.91898 11.5801L8.83109 11.9961C8.54398 12.3418 7.91117 12.5938 7.36625 12.5938C6.66313 12.5938 6.3643 12.1719 6.55766 11.2754L7.29594 7.80664C7.36039 7.51367 7.3018 7.4082 7.00883 7.33789L6.55766 7.25586L6.63969 6.875L8.9307 6.58789ZM8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55229 3.5 9 3.94772 9 4.5C9 5.05228 8.55229 5.5 8 5.5Z" fill="#ADB5BD"/>
                    </svg>
                    <p>Choose tier from the list and autofill sale prices for all currencies or enter these values manually</p>
                </div>
            </div>
            <hr className="sales-horizontal-line"/>
            <SalesInputPrices
                setSalePrice={setSalePrice}
                price={price}/>
        </>
    );
};

export default SalesComponent;

