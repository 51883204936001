import React from 'react';
import AddNewInappBtn from "../AddNewInappBtn/AddNewInappBtn";
import styles from './NoResults.module.css'
import {CONTENT_EDITOR_ROLE} from "@constants/ENV";

const NoResults = ({title, button = false, group, category, canAddSale}) => {
    const role = group === CONTENT_EDITOR_ROLE;
    return (
        <div className={styles.noResultsWrapper}>
            <div>{title}</div>
            {
                category === 'all' && button && role &&
                <div>
                    <AddNewInappBtn styles={'light'}/>
                </div>
            }
            {
                category === 'sales' && role && canAddSale &&
                <AddNewInappBtn styles={'light'}
                                label={'Add sale'}
                                pathPart={'/new-sale'}
                />
            }
            {
                category === 'sales' && !canAddSale &&
                <>You can't add a sale because no in-app has been published yet.</>
            }
        </div>
    );
};

export default NoResults;