import React from 'react';
import ModalRollback from "@UIComponents/Modal/ModalRollback";
import {useFormikContext} from "formik";
import submitData from "@inappPages/InappsPage/methods/submitData";
import useInappStatus from "@hooks/useInappStatus";
import {CONTENT_EDITOR_ROLE, GAME_QA_ROLE} from "@constants/ENV";

const RollbackButtons = () => {
    const group = localStorage.getItem('group');
    const status = useInappStatus();
    const {values} = useFormikContext()

    if (status === 'Ready' && (group === GAME_QA_ROLE)) {
        return <ModalRollback
            methodName={'rollbackPurchase'}
            submitData={submitData}
            modalText={'Are you sure you want to Roll back to “On review”?'}
            btnText={'Roll back to “On review”'}
            confirmBtnText={'Yes, roll back'}
            variant="outline-primary"
        />
    } else if (status === 'OnReview' && values.hasActivePurchase && (group === CONTENT_EDITOR_ROLE)) {
        return <ModalRollback
            methodName={'deletePurchase'}
            submitData={submitData}
            modalText={'Are you sure you want to roll back to “Active”?'}
            description={'Confirming "Yes, roll back" will remove all changes you made previously and the in-app will return to Active status.'}
            btnText={'Roll back to “Active”'}
            confirmBtnText={'Yes, roll back'}
        />
    }
};

export default RollbackButtons;